import { Box, Button, IconButton, Input, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import '../styles/ForgotPassword.css'
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { Repository } from '../../data/repository/repository';

export default function ForgotPasswordScreen() {
    const logo = require('../images/yerimLogo.png')
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [codeSent, setCodeSent] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const navigate = useNavigate();

    const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value.charAt(0).toLowerCase() + value.slice(1));
    }

    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value.toUpperCase());
    }

     const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
        if (passwordError && e.target.value.length >= 6) {
            setPasswordError(false);
        }
    }

    const handleSendRecoveryCode = async () => {
        try {
            const response = await Repository.sendRecoveryCode({email});
            if (response === true) {
                setCodeSent(true)
            } else if (response === false){
                setCodeSent(false)
                toast.error('E-mail adresi bulunamadı. Lütfen e-mail adresinizi kontrol edin.');
            }
            else {
                setCodeSent(false)
                toast.error('Kod gönderilemedi.');

            }
        } catch (error) {
            console.error('Code error:', error);
            toast.error('Kod gönderilemedi. Lütfen tekrar deneyin.');
        }
    }


    const handleVerifyRecoveryCode = async () => {
        if (!code) {
            toast.error('Kod alanı boş bırakılamaz. Lütfen doğrulama kodunu girin.');
            return;
        }
        if (!newPassword) {
            setPasswordError(true);
            toast.error('Lütfen yeni şifrenizi belirleyin.');
            return;
        }
        if (newPassword.length < 6) {
            setPasswordError(true);
            toast.error('Şifreniz en az 6 haneli olmalıdır.');
            return;
        }
        try {
            const response = await Repository.verifyRecoveryCode(email, code, newPassword);
            if (response) {
                toast.success('Şifreniz başarıyla değiştirildi!')
                setTimeout(() => {
                    navigate('/');
                }, 1000);
            } else {
                toast.error('Bir hata meydana geldi. Lütfen tekrar deneyin.')
            }
        } catch (error) {
            console.error('Code error:', error);
            toast.error('Bir hata meydana geldi. Lütfen tekrar deneyin.')
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (codeSent) {
            handleVerifyRecoveryCode();
        } else {
            handleSendRecoveryCode();
        }
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <Box className="overlay">
            <Box className="container-box">
                <Box display="flex" justifyContent="center" alignItems="center" mb="20px">
                    <img style={{ width: '150px', marginBottom: '35px' }} src={logo} alt="Yerim Logo" />
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box className="form-group">
                        <label className='explanation'>Lütfen hesabınıza ait e-mail adresinizi girin.</label>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                                label="E-Mail"
                                value={email}
                                onChange={handleEmail}
                                disabled= {codeSent === true}
                                variant="outlined"
                                fullWidth
                                style={{ marginBottom: '15px' }}
                            />

                        </Box>
                        {codeSent && (
                            <Box display="flex" flexDirection="column" alignItems="center" mt="20px">
                                <TextField
                                    label="Gelen Kodu Girin"
                                    variant="outlined"
                                    value={code}
                                    onChange={handleCodeChange}
                                    fullWidth
                                    style={{ marginBottom: '15px' }}
                                />
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    className="input-with-icon"
                                    label="Yeni Şifrenizi Girin"
                                    value={newPassword}
                                    onChange={handleNewPasswordChange}
                                    variant="outlined"
                                    fullWidth
                                    style={{ marginBottom: '24px' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={togglePasswordVisibility} edge="end">
                                                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>

                        )}
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Button fullWidth variant="contained" color="warning" type="submit">
                                {codeSent === true ? 'Kodu Onayla' : 'Kod Gönder'}
                            </Button>
                        </Box>
                        <div className='bottom-link'>
                        <a href='/' className='recovery-password-link'>
                            Ana Sayfaya Dön
                        </a>
                        </div>
                       
                    </Box>
                </form>

            </Box>
        </Box>
    )
}
