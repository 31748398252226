import { cdnBaseUrl } from '../../api/baseUrl'
import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'


interface Props {
    onClose: () => void
    notificationInfo: {
        title: string,
        content: string,
        imageUrl: string | null,
        creationDate: string;
    }
}


export default function NotificationsDetailPopup({ onClose, notificationInfo }: Props) {
    const bg = require('../images/icons/Sol_color.png')


    return (
        <div className='container-fluid notification-details-popup'>
            <div className='row'>
                <div className='col-sm-2 backgroundDiv'>
                    <img style={{ height: '100%' }} src={bg} />

                </div>
                <div className='col-sm-10'>
                    <div className='stopSession' style={{ padding: '15px' }}>
                        <h4 style={{ fontWeight: '100', padding: '8px' }}>Bildirimler</h4>
                        <button style={{ width: '120px', padding: '8px', margin: '5px' }} onClick={onClose}><AiOutlineClose style={{ color: '#F15F50', marginRight: '5px' }} />Kapat</button>
                    </div>
                    <hr style={{ width: '100%' }} />
                    <div style={{ padding: '15px' }}>
                        <label style={{ color: '#1D1E2599', fontSize: '10px', marginBottom: '10px' }} >{notificationInfo.creationDate}</label>
                        <p style={{ fontSize: '18px', fontWeight: '700' }}>{notificationInfo.title}</p>
                        {notificationInfo.imageUrl ? (
                            <div>
                                <img style={{ width: '200px', height: '170px', borderRadius: '8px' }} src={`${notificationInfo.imageUrl}`} /><br /><br />
                            </div>

                        ) : ''}
                        <label style={{ color: '#1D1E2599', fontSize: '16px', marginBottom: '10px' }} >{notificationInfo.content}</label>
                        

                    </div>
                </div>
            </div>
        </div>
    )
}
