import React, { useState } from 'react';
import { Editor} from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

interface MyEditorProps {
  onSaveContent: (inAppContent: string) => void;
}

const MyEditor: React.FC<MyEditorProps> = ({ onSaveContent }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const getEditorContent = (editorState: EditorState): string => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    // const blocks = htmlContent.blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    const htmlContent = draftToHtml(rawContentState)
    return htmlContent;
  };
  

  const onEditorStateChange = (newState: EditorState) => {
    setEditorState(newState);
  };

  const handleSave = () => {
    const content = getEditorContent(editorState); 
    onSaveContent(content);
    toast.success('Kaydedildi!')
  };

  

  return (
    <div>
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
      />
      <Button variant="contained" onClick={handleSave}>Kaydet</Button>
    </div>
  );
};

export default MyEditor;
