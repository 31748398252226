import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';

export interface AgreementProps {
    onClose: () => void,
    agreementText:string
  }

const AgreementScreen: React.FC<AgreementProps> = ({ onClose, agreementText }) => {
   
  return (
    <div className='agreement'>
         <div className='closeButton'>
                <button onClick={onClose}><AiOutlineClose style={{ color: '#F15F50', marginRight: '5px' }} />Kapat</button>

            </div>
            <div className='agreement-text-div'>
            <div className='agreement-text' dangerouslySetInnerHTML={{ __html: agreementText }} />
            </div>
    </div>
  )
}

export default AgreementScreen