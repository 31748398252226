

export class ProjectFiles {


    static readonly LEFT_COLUMN_BACKGROUND = '../images/icons/Sol_color.png'
}



export class Constants {
    public static readonly DEFAULT_CAMPAIGN_TIME_OPTIONS_COUNT = 10
    public static readonly CAMPAIGN_TIME_OPTIONS_NIGHTTIME_THRESHOLD = 360 // in minutes
    public static readonly CAMPAIGN_TIME_NIGHTTIME_EXPAND = 240 // in minutes

    public static readonly AWAIT_REFRESH_TOKEN_MAX_DURATION = 5000
    public static readonly AWAIT_REFRESH_TOKEN_GRANULARITY = 1000


    public static readonly COMPANY_SEGMENT_OPTIONS = [
        { value: "option1", label: "Fast Food" },
        { value: "option2", label: "Dönerci" },
        { value: "option3", label: "Hamburgerci" },
        { value: "option4", label: "Pizzacı" },
        { value: "option5", label: "Kahve Dükkanı" },
        { value: "option6", label: "Börekçi" },
        { value: "option7", label: "Et ve Köfte" },
        { value: "option8", label: "Tavuk" },
        { value: "option9", label: "Mantıcı" },
        { value: "option10", label: "Kahvaltılık" },
        { value: "option11", label: "Atıştırmalık" },
        { value: "option12", label: "Türk Mutfağı" },
        { value: "option13", label: "İtalyan Mutfağı" },
        { value: "option14", label: "Orta Doğu Mutfağı" },
        { value: "option15", label: "Çin Mutfağı" },
        { value: "option16", label: "Sushi / Japon Mutfağı" },
        { value: "option17", label: "Meze ve Akdeniz Mutfağı" },
        { value: "option18", label: "Deniz Ürünleri" },
        { value: "option19", label: "Kokoreç ve Midye" },
        { value: "option20", label: "Vegan / Vejeteryan" },
        { value: "option21", label: "Tatlı ve Pastane" },
        { value: "option22", label: "Dünya Mutfağı" },
        { value: "option23", label: "Özel Mutfak" }
    ]

    public static readonly COMPANY_TYPE_OPTIONS = [
        { value: "option1", label: "Restoran" },
        { value: "option2", label: "Büfe" },
        { value: "option3", label: "Yemekhane" },
        { value: "option4", label: "Kafe" },
        { value: "option5", label: "Kiosk" }
    ]
}


export class Formats {
    public static readonly PROJECT_DATE_FORMAT = "DD/MM/YYYY HH:mm:ss"
}


export class PremadeRegex {
    public static readonly DECIMAL_REGEX = /^\d*\.?\d*$/;
}

