
export class LocationHelper {
    static getCoordinatesFromString(input: string): string[] {
        try {
            //Expected input values: "38.67856314285213, 35.521882515343414", "(38.67856314285213, 35.521882515343414)"
            //Sadece sayılar, nokta ve virgül karakterlerini tutan bir regex ifadesi kullanarak filtre yap
            const filteredString = input.replace(/[^0-9.,]/g, '');
            return filteredString.split(',');
        }
        catch (error) {
            return [];
        }
    }
}