import {useNavigate} from "react-router-dom";

export const routeRegister = "/register"
export const routeHome = '/home/*'
export const routeSuperAdmin = '/super-admin'
export  const routeForgotPassword = '/forgot-password'
export const routeDeleteAccount = '/delete-account'
export const routeLogin = '/'

export const routeHomeFlashDeals = '/home/flash-deals'
export const routeHomeStatistics = '/home/statistics'
export const routeHomeMyRestaurant = '/home/my-restaurant'


export const routeAboutUs = '/home/about-us'
export const routeTermsAndConditions = '/home/terms-and-conditions'
export const routePrivacyPolicy = '/home/privacy-policy'





export class RoutingHelper {


    static navigateByUrl(path: string) {
        window.location.href = path
    }



}