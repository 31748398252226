import React from 'react'
import '../styles/style.css'
export default function AboutUsScreen() {
    const bg = require('../images/icons/Sol_color.png');

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-2">
                    <div className='backgroundDiv'>
                        <img src={bg} alt="background" />
                    </div>
                </div>
                <div className="col-sm-10">
                    <div className='contentDiv'>
                        <div className='titleOnline'>
                            <h2>
                                Hakkımızda
                            </h2>
                            <hr style={{ width: '550px' }} />
                        </div>
                        <div className='row upperContent'>
                            <h6 style={{ color: '#1D1E2566' }}><label style={{ color: '#F15F50' }}>Güncellenme:</label> 12.10.2024</h6>
                            <p>
                                Yerim uygulaması, yemek severleri en sevdikleri yerel restoranlarla buluşturmayı amaçlayan yenilikçi bir platformdur. Kullanıcılarımızın çevrelerindeki yeme-içme mekanlarını keşfetmelerini, anında indirimlerden yararlanmalarını ve benzersiz lezzet deneyimleri yaşamalarını sağlamak için buradayız.

                                Yerim, herkesin "ne yesem?" sorusuna pratik ve ekonomik çözümler sunar. Kullanıcılarımız, bulundukları konuma göre restoranları keşfedebilir, promosyonlar hakkında bilgi alabilir ve özel indirimlerden yararlanabilirler.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
