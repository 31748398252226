import React from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import '../styles/style.css'
interface Props {
    onClose: () => void,
    onRestart:() => void,
    code: string,
    details: {
        campaignMeta: { discountRate: string, promotionType: number,title:string }
        consumptionDate: string
    } | null
}


export default function QRConsumeResponsePopup({ onClose,onRestart, details }: Props) {
    const arrow = require('../images/icons/Text--new-line.png')
    const yuzde = require('../images/icons/yuzde.png')
    const time = require('../images/icons/Time.png')

    // const formatDate = (dateString: string) => {
    //     const date = new Date(dateString);

    //     const day = date.getDate().toString().padStart(2, '0');
    //     const month = (date.getMonth() + 1).toString().padStart(2, '0');
    //     const year = date.getFullYear();
    //     const hours = date.getHours().toString().padStart(2, '0');
    //     const minutes = date.getMinutes().toString().padStart(2, '0');

    //     return `${day}.${month}.${year} - ${hours}:${minutes}`;
    // };



    return (
        <div className='thanksPopUp'>
            <div className='closeButton'>
                <button onClick={onClose}><AiOutlineClose style={{ color: '#F15F50', marginRight: '5px' }} />Kapat</button>

            </div>
            <div className='okTitle'>
                <h1>👍</h1>
            </div>

            <h1>Teşekkürler</h1>
            <p className='qrSuccess'>QR Kod başarılı bir şekilde girildi.</p>

            {details?.campaignMeta.promotionType === 0 && (
                <div>
                    <p className='subtitle'>Uygulamanız Gereken İndirim Oranı:</p>
                    <p style={{ fontSize: '60px', fontWeight: '700' }}><img style={{ width: '25px' }} src={yuzde} /> {details?.campaignMeta?.discountRate}</p>
                </div>
            )}
            {details?.campaignMeta.promotionType === 1 && (
                <div>
                    <p style={{ fontSize: '30px', fontWeight: '500' }}>{details?.campaignMeta?.title}</p>
                </div>
            )}

            <p className='subtitle'>Kullanım Tarihi</p>
            <label><img style={{ width: '25px' }} src={time} />  {details?.consumptionDate}</label>
            <div className='qr-consume-success-button'>
                <button onClick={onRestart} className='confirmButtonNo' style={{ width: '300px' }}><img src={arrow} /> Yeni Bir QR Kod Okut</button>
            </div>



        </div>
    )
}
