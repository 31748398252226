import React, { useEffect, useRef, useState } from 'react'
import { Form, Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import '../styles/cominSoonStyles.css'
import { BsChevronDown, BsFillCaretDownFill, BsInfoCircle } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { NeighborhoodDTO } from '../../model/dto/NeighborhoodDTO';
import { DistrictDTO } from '../../model/dto/DistrictDTO';
import { FaEye, FaEyeSlash, FaTimes } from 'react-icons/fa';
import { CompanyPreview } from '../popup/CompanyPreview';
import { CompanyDTO } from '../../model/dto/CompanyDTO';
import { CityDTO } from '../../model/dto/CityDTO';
import { Location } from '../../model/common/Location';
import { Address } from '../../model/common/Address';
import LocalStorageService from '../../data/services/local/LocalStorageService';
import { cdnBaseUrl } from '../../api/baseUrl';
import { Validator } from 'fluentvalidation-ts';
import { LocationValidator } from '../../model/common/Location';
import { AddressValidator } from '../../model/common/Address';
import {
  CompanyDelegateValidator,
  CreateCompanyRequest,
  CreateCompanyRequestFactory,
  CreateCompanyRequestValidator,
  TaxCertificateValidator,
  WorkingHoursType
} from '../../model/request/CreateCompanyRequest';
import HomeLandingScreen from './HomeLandingScreen';
import { Box, InputAdornment, MenuItem, TextField } from '@mui/material';
import { Repository } from '../../data/repository/repository';
import { Constants, PremadeRegex } from "../../constants/constants";
import { TimeHelper } from "../../helpers/TimeHelper";
import { ReflectionHelper } from "../../helpers/ReflectionHelper";
import { LocationHelper } from "../../helpers/LocationHelper";
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import GoogleMap from './GoogleMap';
import { StreetDTO } from '@/model/dto/StreetDTO';


export default function RestaurantUpdateScreen() {

  // region images
  const enterpriseIcon = require('../images/icons/Enterprise.png');
  const noodleIcon = require('../images/icons/Noodle-bowl.png')
  const userIcon = require('../images/icons/User.png')
  const phoneIcon = require('../images/icons/Phone.png')
  const versionIcon = require('../images/icons/Version.png')
  const timerIcon = require('../images/icons/Timer.png')
  const mobileAddIcon = require('../images/icons/Mobile--add.png')
  const cameraActionIcon = require('../images/icons/Camera--action.png')
  const TRIcon = require('../images/icons/TR.png')
  const lockIcon = require('../images/icons/Locked.png')
  const keyIcon = require('../images/icons/Password.png')
  const arrow = require('../images/icons/Text--new-line.png')
  const preview = require('../images/icons/Application--mobile.png')
  const emailIcon = require('../images/icons/Email--new.png')
  const pdfIcon = require('../images/icons/pdfIcon.png')

  const yerimLogo = require('../images/yerimLogo.png')

  const uploadIcon = require('../images/icons/Cloud--upload.png')
  // endregion



  //const [segmentMessage, setSegmentMessage] = useState('');
  const [company, setcompany] = useState<CompanyDTO | null>(Repository.getMyCompany());
  const [cities, setCities] = useState<CityDTO[] | null>();
  const [districts, setDistricts] = useState<DistrictDTO[] | null>();
  const [neighborhoods, setNeighborhoods] = useState<NeighborhoodDTO[] | null>()
  const [streets, setStreets] = useState<StreetDTO[] | null>([]);


  const [selectedCity, setSelectedCity] = useState<CityDTO | null>()
  const [selectedDistrict, setSelectedDistrict] = useState<DistrictDTO | null>()
  const [selectedNeighborhood, setSelectedNeighborhood] = useState<NeighborhoodDTO | null>()
  const [selectedStreet, setSelectedStreet] = useState <StreetDTO | null> ()
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [activeButton, setActiveButton] = useState('companyInfo');

  const [selectedSegments, setSelectedSegments] = useState<string[]>([]);
  const [submitTried, setSubmitTried] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);


  const formGroupTaxInfo = 'formGroupTaxInfo';
  const formGroupCompanyInfo = 'formGroupCompanyInfo';
  const formGroupAddressInfo = 'formGroupAddressInfo';
  const formGroupPassWordInfo = 'formGroupPassWordInfo';
  const formGroupAgreementInfo = 'formGroupAgreementInfo';
  const [activeFormGroup, setActiveFormGroup] = useState<string>(formGroupCompanyInfo);

  //const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);

  const [latLng, setLatLng] = useState<{ lat: number; lng: number } | null>(
    null
  );


  const hoursOptions = Array.from({ length: 24 }, (_, i) => `${i}:00`);

  const [companyInfoValidationResult, setCompanyInfoValidationResult] = useState<any>();
  const [locationInfoValidationResult, setLocationValidationResult] = useState<any>();
  const [addressInfoValidationResult, setAddressInfoValidationResult] = useState<any>();
  const [delegateInfoValidationResult, setDelegateInfoValidationResult] = useState<any>();
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const [agreement, setAgreement] = useState('')


  const [formData, setFormData] = useState<CreateCompanyRequest | null>(CreateCompanyRequestFactory.build(Repository.getMyCompany()));

  useEffect(() => {
    if (company) {

      setFormData(CreateCompanyRequestFactory.build(company))
      setSelectedSegments(company.segments)
    }

    (async () => { setCities(await Repository.getCities()) })(); // IIFE : https://developer.mozilla.org/en-US/docs/Glossary/IIFE

  }, [company]);

  useEffect(() => {
    if (formData !== undefined && submitTried) {
      validateFormData();
    }
  }, [formData]);

  useEffect(() => {
    if (cities && formData?.address?.city) {
      const city = cities.find(city => city.name == formData.address.city)
      setSelectedCity(city);
    }
  }, [cities]);

  useEffect(() => {
    if (selectedCity) {
      setFormData(CreateCompanyRequestFactory.setCity(formData, selectedCity?.name ?? ''));
      (async () => { await getDistricts(selectedCity.id) })();
    }
  }, [selectedCity]);

  useEffect(() => {
    if (districts) {
      const district = districts.find(district => district.name == formData!.address.district);
      setSelectedDistrict(district)
    }
  }, [districts]);

  useEffect(() => {
    if (selectedDistrict || formData?.address.district == null) {
      setFormData(CreateCompanyRequestFactory.setDistrict(formData!, selectedDistrict?.name ?? ''));
      if (selectedDistrict) {
        (async () => { await getNeighborhoods(selectedDistrict.id); })();
      }
      else {
        setNeighborhoods(null)
      }
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (neighborhoods && formData?.address?.neighborhood) {
      const neighborhood = neighborhoods.find(neighborhood => neighborhood.name == formData!.address.neighborhood);
      setSelectedNeighborhood(neighborhood);
    }
    else if (!neighborhoods && formData?.address.neighborhood == null) {
      setSelectedNeighborhood(null)
    }
  }, [neighborhoods]);

  useEffect(() => {
    if (selectedNeighborhood || formData?.address?.neighborhood == null) {
      setFormData(CreateCompanyRequestFactory.setNeighborhood(formData!, selectedNeighborhood?.name ?? ''))
    }

  }, [selectedNeighborhood]);


  useEffect(() => {
    if (streets && formData?.address?.street) {
      const street = streets.find(street => street.name == formData!.address.street);
      setSelectedStreet(street);
    }
    else if (!streets && formData?.address.street == null) {
      setSelectedStreet(null)
    }
  },[streets])

  useEffect(() => {
    if (selectedNeighborhood) {
      (async () => { await getStreets(selectedNeighborhood.id) })();
    } else {
      setStreets(null);
    }
  }, [selectedNeighborhood]);
  

  useEffect(() => {
    if (selectedStreet || formData?.address?.street == null) {
      setFormData(CreateCompanyRequestFactory.setStreet(formData!, selectedStreet?.name ?? ''))
    }

  }, [selectedStreet]);

  const getStreets = async (selectedId:string) => {
    const streets = await Repository.getStreetsById(selectedId)
    if (streets?.length ?? 0 > 0) {
      setStreets(streets)
    }
  }

  const getDistricts = async (selectedId: string) => {
    const districts = await Repository.getDistrictById(selectedId);
    if (districts?.length ?? 0 > 0) {
      setDistricts(districts);
    }
  };

  const getNeighborhoods = async (selectedId: string) => {
    const neighborhoods = await Repository.getNeighboorhoodDistrictById(selectedId)
    if (neighborhoods?.length ?? 0 > 0) {
      setNeighborhoods(neighborhoods)
    }
  }



  const handleCityChange = (event: any) => {
    const selectedName = event.target.value;
    const city = cities?.find(city => city.name == selectedName)
    setSelectedCity(city)
  };


  const handleDistrictChange = (event: any) => {
    const district = districts?.find(x => x.name == event.target.value)
    setSelectedDistrict(district)
  };

  const handleNeighborhoodChange = (event: any) => {
    const neighborhood = neighborhoods?.find(x => x.name == event.target.value)
    setSelectedNeighborhood(neighborhood)
  };


  const handleStreetChange = (event: any) => {
    const street = streets?.find(x => x.name == event.target.value);
    setSelectedStreet(street)
  }


  const handleStartTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const startTimeFormatted = TimeHelper.formatTime(event.target.value);
    const clone = ReflectionHelper.deepCopy(formData!);
    clone.workingHours = `${startTimeFormatted} - ${CreateCompanyRequestFactory.extractWorkingHour(clone.workingHours, WorkingHoursType.end) ?? 'Restoran Kapanış*'}`;
    setFormData(clone);
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const endTimeFormatted = TimeHelper.formatTime(event.target.value);
    const clone = ReflectionHelper.deepCopy(formData!);
    clone.workingHours = `${CreateCompanyRequestFactory.extractWorkingHour(clone.workingHours, WorkingHoursType.start) ?? 'Restoran Açılış*'} - ${endTimeFormatted}`;
    setFormData(clone);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  function validateData<T>(validator: Validator<T>, validationGroup: string, data: T): any {

    const validateResult: any = validator.validate(data);

    let errorCount = Object.keys(validateResult).length;
    return validateResult
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const ourData = ReflectionHelper.deepCopy(formData!);
    if (e.target.name === 'name') {
      ourData.name = e.target.value
    }
    else if (e.target.name === 'type') {
      ourData.type = e.target.value
    }
    else if (e.target.name === 'delegateName') {

      const numberRegex = /\d/;

      if (numberRegex.test(e.target.value)) {
        return;
      }

      ourData.delegate.delegateName = e.target.value
    }
    else if (e.target.name === 'delegateSurname') {

      const numberRegex = /\d/;

      if (numberRegex.test(e.target.value)) {
        return;
      }

      ourData.delegate.delegateSurname = e.target.value
    }
    else if (e.target.name === 'delegatePosition') {
      ourData.delegate.delegatePosition = e.target.value
    }
    else if (e.target.name === 'ownerPhoneNumber') {

      const numberRegex = /^(?:\d+|\s*)$/;

      if (!numberRegex.test(e.target.value)) {
        return;
      }

      ourData.ownerPhoneNumber = e.target.value
    }
    else if (e.target.name === 'phoneNumber') {

      const numberRegex = /^(?:\d+|\s*)$/;

      if (!numberRegex.test(e.target.value)) {
        return;
      }

      ourData.phoneNumber = e.target.value
    }
    else if (e.target.name === 'workingHours') {
      ourData.workingHours = e.target.value
    }
    else if (e.target.name === 'capacity') {
      const numericValue = value.replace(/[^0-9]/g, '')
      if (e.target.value === '') {
        ourData.capacity = null
      }
      else {
        ourData.capacity = Number(numericValue);
      }
    }
    else if (e.target.name === 'street') {
      ourData.address.street = e.target.value
    }
    else if (e.target.name === 'buildingNo') {
      ourData.address.buildingNo = e.target.value
    }
    else if (e.target.name === 'webSiteLink') {
      ourData.webSiteLink = e.target.value
    }
    else if (e.target.name === 'email') {
      ourData.email = handleEmailChange(value);


    } else if (e.target.name === 'tradeName') {
      ourData.taxCertificate.tradeName = e.target.value

    } else if (e.target.name === 'taxOffice') {
      ourData.taxCertificate.taxOffice = e.target.value

    } else if (e.target.name === 'taxNo') {

      const numberRegex = /^(?:\d+|\s*)$/;

      if (!numberRegex.test(e.target.value)) {
        return;
      }

      ourData.taxCertificate.taxNo = e.target.value

    } else if (e.target.name === 'taxPayerName') {

      const numberRegex = /\d/;

      if (numberRegex.test(e.target.value)) {
        return;
      }

      ourData.taxCertificate.taxPayerName = e.target.value

    } else if (e.target.name === 'identificationNumber') {
      var numberRegex = /^(?:\d+|\s*)$/;

      if (!numberRegex.test(e.target.value)) {
        return;
      }

      ourData.taxCertificate.identificationNumber = e.target.value
    }

    ourData.labels = ['']

    setFormData(ourData)
  };

  const getCoordinatesFromString = (input: string): string[] => {

    try {
      //Expected input values: "38.67856314285213, 35.521882515343414", "(38.67856314285213, 35.521882515343414)"
      //Sadece sayılar, nokta ve virgül karakterlerini tutan bir regex ifadesi kullanarak filtre yap
      var filteredString = input.replace(/[^0-9.,]/g, '');
      return filteredString.split(',');
    }
    catch (error) {
      return [];
    }
  }

  const handleEmailChange = (value: string): string => {
    const firstChar = value.charAt(0).replace('İ', 'i').toLowerCase()
    return firstChar + value.slice(1);
  };

  const handleSegmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = e.target.value;
    let updatedSegments = [...selectedSegments];

    if (updatedSegments.length < 3 && !updatedSegments.includes(selectedOption)) {
      updatedSegments.push(selectedOption);
    }
    else if (updatedSegments.length === 3 && !updatedSegments.includes(selectedOption)) {
      toast.error("En fazla üç adet segment seçebilirsiniz")
    }
    setSelectedSegments(updatedSegments);

    const updatedFormData = ReflectionHelper.deepCopy(formData!);
    updatedFormData.segments = updatedSegments;

    setFormData(updatedFormData);

  };


  const handleRemoveSegment = (segment: string) => {
    const updatedSegments = selectedSegments.filter((item) => item !== segment);
    setSelectedSegments(updatedSegments);

    const updatedFormData = ReflectionHelper.deepCopy(formData!);
    updatedFormData.segments = updatedSegments;

    setFormData(updatedFormData);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const temp = ReflectionHelper.deepCopy(formData!)

    temp.password = value

    setFormData(temp);

    if (temp.password !== confirmPassword) {
      setErrorMessage('Şifreler eşleşmiyor');
    } else {
      setErrorMessage('');
    }
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  const handlePasswordConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const temp = formData!;

    if (temp.password !== value) {
      setErrorMessage('Şifreler eşleşmiyor');
    } else {
      setErrorMessage('');
    }

    setConfirmPassword(value);
  };


  // const handleContractUpload = async (e: React.ChangeEvent<HTMLInputElement>, contractNumber: string) => {
  //   const file = e.target.files && e.target.files[0];

  //   if (file) {
  //     try {
  //       const contractUrl = await uploadDocument(file);
  //       setFormData(prevFormData => {
  //         if (!prevFormData) {
  //           return prevFormData;
  //         }
  //         const updatedContract = { ...prevFormData };
  //         if (contractNumber === '1') {
  //           updatedContract.contractUrl1 = contractUrl;
  //           setContractPhotoUrl1(contractUrl)
  //         } else if (contractNumber === '2') {
  //           updatedContract.contractUrl2 = contractUrl;
  //           setContractPhotoUrl1(contractUrl)

  //         }
  //         return updatedContract;
  //       });
  //     } catch (error) {
  //       console.error(`Contract upload failed:`, error);
  //     }
  //   }
  // };


  const handleLogoUrlChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const uploadResult = await Repository.uploadFile(file) ?? null;
      const clone = ReflectionHelper.deepCopy(formData!);
      clone.logoUrl = `${uploadResult?.url ?? ''}`
      setFormData(clone);
    }
  };


  const handleCompanyPhotos = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const uploadResult = await Repository.uploadFile(file) ?? null
      const clone = ReflectionHelper.deepCopy(formData!);
      clone.photoUrl = `${uploadResult?.url ?? ''}`
      setFormData(clone);
    }
  };

  const validateFormData = () => {
    var createCompanyRequestValidationResult = validateData(new CreateCompanyRequestValidator(), 'create-company-request', formData!)
    var locationValidationResult = validateData(new LocationValidator(), 'location', formData!.location)
    var addressValidationResult = validateData(new AddressValidator(), 'address', formData!.address)
    var companyDelegateValidationResult = validateData(new CompanyDelegateValidator(), 'company-delegate', formData!.delegate)
    var taxCertificateValidationResult = validateData(new TaxCertificateValidator(), 'tax-certificate', formData!.taxCertificate)

    setCompanyInfoValidationResult(createCompanyRequestValidationResult)
    setLocationValidationResult(locationValidationResult)
    setAddressInfoValidationResult(addressValidationResult)
    setDelegateInfoValidationResult(companyDelegateValidationResult)


    if (Object.keys(createCompanyRequestValidationResult).length > 0 || Object.keys(locationValidationResult).length > 0 || Object.keys(addressValidationResult).length > 0 || Object.keys(companyDelegateValidationResult).length > 0 || Object.keys(taxCertificateValidationResult).length > 0) {
      return false;
    }

    return true;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSubmitTried(true);

    const isValid = validateFormData();

    if (!isValid) {
      toast.error('Boş alan kalmamalı. Lütfen gerekli alanları doldurunuz.')
      return;
    }

    if (formData?.password) {
      const isValidPassword = (password: string): boolean => {
        const regex = /^.{6,}$/;
        return regex.test(password);
      };


      if (!isValidPassword(formData?.password ?? '')) {
        setErrorMessage('Şifreniz en az 6 karakter uzunluğunda olmalıdır.');
        return;
      }

      if (formData?.password != confirmPassword) {
        setErrorMessage('Parolalar eşleşmiyor');
        return;
      }
    }
    const clone = ReflectionHelper.deepCopy(formData!)
    setErrorMessage('');

    clone.labels = [...clone.segments ?? []]

    try {
      if (clone) {
        const updatedCompany = await Repository.updateCompany(clone, Repository.getMyCompany()?.id ?? '');
        if (updatedCompany != null)
          Repository.setMyCompany(updatedCompany)
        toast.success('Güncelleme İşlemi Başarılı!');
      }
      else {
        toast.error('Bir Hata Meydana Geldi');
      }
    }
    catch (error) {
      console.error('Bir Hata Meydana Geldi', error);
    }
  };


  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (!PremadeRegex.DECIMAL_REGEX.test(e.target.value)) {
      const coordinates = LocationHelper.getCoordinatesFromString(e.target.value);

      if (coordinates.length === 0 || coordinates.includes('')) {
        return;
      }

      const clone = ReflectionHelper.deepCopy(formData!)
      clone.location.latitude = Number(coordinates[0]);
      clone.location.longitude = Number(coordinates[1]);

      setFormData(clone);
      return;
    }

    if (e.target.name == 'longitude') {
      const clone = ReflectionHelper.deepCopy(formData!)
      clone.location.longitude = Number(e.target.value)
      setFormData(clone)
    }
    else if (e.target.name == 'latitude') {
      const clone = ReflectionHelper.deepCopy(formData!)
      clone.location.latitude = Number(e.target.value)
      setFormData(clone)
    }
  };










  const openTaxOfficeForm = () => {
    setActiveButton('taxOffice')
    setActiveFormGroup(formGroupTaxInfo)
  }

  const openCompanyInfoForm = () => {
    setActiveButton('companyInfo')
    setActiveFormGroup(formGroupCompanyInfo)
  }

  const openAddressForm = () => {
    setActiveButton('addressInfo')
    setActiveFormGroup(formGroupAddressInfo)
  }

  const openPasswordForm = () => {
    setActiveButton('passwordInfo')
    setActiveFormGroup(formGroupPassWordInfo)
  }

  const openAgreementForm = () => {
    setActiveButton('agreementInfo')
    setActiveFormGroup(formGroupAgreementInfo)
  }

  const getCompanyAgreement = async () => {
    const response = await Repository.getCompanyAgreement(Repository.getMyCompany()?.id ?? '')
    if (response) {
      setAgreement(response)
    }

  }


  function test(address: string) {
    fromAddress(address)
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        setLatLng({ lat, lng })
        const clone = ReflectionHelper.deepCopy(formData!)
        clone.location.latitude = lat;
        clone.location.longitude = lng;

        setFormData(clone);
      })
      .catch(console.error);
  }



  function geoCodeAddress() {
    if (formData?.address.city === null) return
    if (formData?.address.district === null) return
    if (formData?.address.neighborhood === null) return
    if (formData?.address.street === null) return
    if (formData?.address.buildingNo === null) return
    const address = `${formData?.address.street} ${formData?.address.buildingNo}, ${formData?.address.neighborhood}, ${formData?.address.district}, ${formData?.address.city}`;
    test(address);
  }

  useEffect(() => {
    geoCodeAddress()
  }, [formData?.address.street, formData?.address.buildingNo])

  useEffect(() => {
    getCompanyAgreement()
    setKey('AIzaSyCC4PLX2UOWJdVSyABDAJbTnme_jnoHn40')
  }, []);

  useEffect(() => {
    console.log("Working hours updated: ", formData?.workingHours);
  }, [formData?.workingHours]);




  if (!company) {
    return <div><HomeLandingScreen /></div>;
  }
  const getHeightandBg = () => {
    switch (activeButton) {
      case 'companyInfo':
        return { bg: require('../images/Sol_color_firma_info.png'), height: '1714px' }
      case 'addressInfo':
        return { bg: require('../images/Sol_color_firma_info.png'), height: '1714px' }
      case 'passwordInfo':
        return { bg: require('../images/Sol_color_password_taxOffice.png'), height: '890px' }
      case 'taxOffice':
        return { bg: require('../images/Sol_color_password_taxOffice.png'), height: '890px' }
      case 'agreementInfo':
        return { bg: require('../images/Sol_color_long.png'), height: '6432px' }
      default:
        return { bg: require('../images/Sol_color_firma_info.png'), height: '1714px' }
    }
  }

  const { bg, height } = getHeightandBg()

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-sm-2 backgroundDiv'>
          <img className='restaurant-left-image' src={bg} style={{ height }} />

        </div>
        <div className='col-sm-10'>
          <div className='restaurant-container'>
            <h4 style={{ marginTop: '20px', textAlign: 'left', fontWeight: '100', fontSize: '40px' }}>Restoranım</h4>
            <hr style={{ width: '95%' }} />
            <div>
              <div className='restaurant-buttons-container'>
                <div className='company-select-buttons'>
                  <button className={`company-select-button ${activeButton === 'companyInfo' ? 'active' : ''}`} onClick={openCompanyInfoForm}>Firma Bilgileri</button>
                  <button className={`company-select-button ${activeButton === 'addressInfo' ? 'active' : ''}`} onClick={openAddressForm}>Adres Bilgileri</button>
                  <button className={`company-select-button ${activeButton === 'passwordInfo' ? 'active' : ''}`} onClick={openPasswordForm}>Şifre İşlemleri</button>
                  <button className={`company-select-button ${activeButton === 'taxOffice' ? 'active' : ''}`} onClick={openTaxOfficeForm}>Vergi Levhası</button>
                  <button className={`company-select-button ${activeButton === 'agreementInfo' ? 'active' : ''}`} onClick={openAgreementForm}>Sözleşme</button>
                </div>
              </div>
              <div className='companyInfoFormContainer'>
                <div className='companyInfoForm'>
                  <Form onSubmit={handleSubmit}>
                    {activeFormGroup === formGroupTaxInfo ? (
                      <div>
                        <h6 className='restaurant-update-screen-titles' id='taxCertificate'>Vergi Levhası Bilgileri</h6>
                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='tradeName' data-fluent-validation-group='tax-certificate'></div>
                          <div className="input-container">
                            <img className='icons' src={enterpriseIcon} />
                            <Form.Control
                              type="text"
                              placeholder={'Ticaret Ünvanı'}
                              name="tradeName"
                              value={formData?.taxCertificate?.tradeName}
                              onChange={handleChange}

                            />
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="Ticaret Ünvanı"
                              placeholder='Ticaret Ünvanı'
                              name="tradeName"
                              variant="outlined"
                              fullWidth
                              style={{ marginBottom: '24px' }}
                              value={formData?.taxCertificate?.tradeName}
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={enterpriseIcon} />
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>


                        </Form.Group>

                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='taxOffice' data-fluent-validation-group='tax-certificate'></div>
                          <div className="input-container">
                            <img className='icons' src={enterpriseIcon} />
                            <Form.Control
                              type="text"
                              placeholder={'Vergi Dairesi'}
                              name="taxOffice"
                              value={formData?.taxCertificate?.taxOffice}
                              onChange={handleChange}

                            />
                          </div> */}
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="Vergi Dairesi"
                              placeholder='Vergi Dairesi'
                              name="taxOffice"
                              variant="outlined"
                              fullWidth
                              style={{ marginBottom: '24px' }}
                              value={formData?.taxCertificate?.taxOffice}
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={enterpriseIcon} />
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>
                        </Form.Group>

                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='taxNo' data-fluent-validation-group='tax-certificate'></div>
                          <div className="input-container">
                            <img className='icons' src={enterpriseIcon} />
                            <Form.Control
                              type="text"
                              placeholder={'Vergi Kimlik No'}
                              name="taxNo"
                              value={formData?.taxCertificate?.taxNo !== undefined ? formData?.taxCertificate?.taxNo : ''}
                              onChange={handleChange}

                            />
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="Vergi Kimlik No"
                              placeholder='Vergi Kimlik No'
                              variant="outlined"
                              name="taxNo"
                              fullWidth
                              style={{ marginBottom: '24px' }}
                              value={formData?.taxCertificate?.taxNo !== undefined ? formData?.taxCertificate?.taxNo : ''}
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={enterpriseIcon} />
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>
                        </Form.Group>

                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='taxPayerName' data-fluent-validation-group='tax-certificate'></div>
                          <div className="input-container">
                            <img className='icons' src={enterpriseIcon} />
                            <Form.Control
                              type="text"
                              placeholder={'Mükellefin Adı Soyadı'}
                              name="taxPayerName"
                              value={formData?.taxCertificate?.taxPayerName !== undefined ? formData?.taxCertificate?.taxPayerName : ''}
                              onChange={handleChange}

                            />
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="Mükellefin Adı Soyadı"
                              placeholder='Mükellefin Adı Soyadı'
                              variant="outlined"
                              name="taxPayerName"
                              fullWidth
                              style={{ marginBottom: '24px' }}
                              value={formData?.taxCertificate?.taxPayerName !== undefined ? formData?.taxCertificate?.taxPayerName : ''}
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={enterpriseIcon} />
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>
                        </Form.Group>

                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='identificationNumber' data-fluent-validation-group='tax-certificate'></div>
                          <div className="input-container">
                            <img className='icons' src={enterpriseIcon} />
                            <Form.Control
                              type="text"
                              placeholder={'TC Kimlik No'}
                              name="identificationNumber"
                              value={formData?.taxCertificate?.identificationNumber}
                              onChange={handleChange}

                            />
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="TC Kimlik No"
                              placeholder='TC Kimlik No'
                              variant="outlined"
                              name="identificationNumber"
                              fullWidth
                              style={{ marginBottom: '24px' }}
                              value={formData?.taxCertificate?.identificationNumber !== undefined ? formData?.taxCertificate?.identificationNumber : ''}
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={enterpriseIcon} />
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>
                        </Form.Group>
                      </div>
                    ) : ''}





                    {activeFormGroup === formGroupCompanyInfo ? (
                      <div>
                        <h6 className='restaurant-update-screen-titles' id='infoPart'>Firma Bilgileri</h6>
                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'></div>
                          <div className="input-container">
                            <img className='icons' src={enterpriseIcon} />
                            <Form.Control
                              type="text"
                              placeholder={'Firma İsmi'}
                              name="name"
                              value={formData?.name}
                              onChange={handleChange}

                            />
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="Firma İsmi"
                              placeholder='Firma İsmi'
                              variant="outlined"
                              fullWidth
                              required
                              name="name"
                              style={{ marginBottom: '24px' }}
                              value={formData?.name}
                              onChange={handleChange}
                              error={companyInfoValidationResult?.name?.length > 0}
                              helperText={companyInfoValidationResult?.name}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={enterpriseIcon} />
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>

                        </Form.Group>
                        <Form.Group controlId="inputArea">
                          <div className="invalid-feedback" data-fluent-validation-key='type' data-fluent-validation-group='create-company-request'></div>
                          <div className="input-container">
                            <img className='icons' src={noodleIcon} />
                            <div className='select-container' style={{ marginBottom: '44px' }}>

                              <Form.Control
                                as='select'
                                placeholder='Firma Türü'
                                name="type"
                                value={formData?.type}
                                onChange={handleChange}

                              >
                                <option value="" disabled hidden>Firma Türü</option>
                                {Constants.COMPANY_TYPE_OPTIONS.map((option) => (
                                  <option key={option.value} value={option.label}>
                                    {option.label}
                                  </option>
                                ))}
                              </Form.Control>
                              <BsFillCaretDownFill style={{ width: '13px' }} className="dropdown-icon" />

                            </div>
                          </div>
                          <p style={{ marginTop: '-25px', fontSize: '12px', color: '#d23333' }}>
                            {companyInfoValidationResult?.type?.length > 0 ? `${companyInfoValidationResult?.type}` : ''}
                          </p>
                        </Form.Group>
                        <Form.Group controlId="inputArea">
                          <div className="invalid-feedback" data-fluent-validation-key='segments' data-fluent-validation-group='create-company-request'></div>
                          <div className="input-container">
                            <img className='icons' style={{ zIndex: '999' }} src={noodleIcon} />
                            <div className='segments-container'>
                              <div
                                className={`dropdown ${isOpen ? 'open' : ''}`}
                                onClick={toggleDropdown}
                              >
                                <Form.Control
                                  as='select'
                                  placeholder='Firma Segmenti'
                                  name="segment"
                                  value={selectedSegments}
                                  onChange={handleSegmentChange}

                                >
                                  <option value=''>{selectedSegments.length === 0 ? 'Firma Segmenti' : selectedSegments.join(', ')}</option>
                                  {Constants.COMPANY_SEGMENT_OPTIONS.map((option) => (
                                    <option key={option.value} value={option.label}>
                                      {option.label}
                                    </option>
                                  ))}
                                </Form.Control>
                                {/* <BsFillCaretDownFill style={{ width: '13px' ,zIndex:'999'}} className="dropdown-icon" /> */}
                              </div>
                            </div>
                          </div>
                          <div className="selected-segments">
                            {selectedSegments.map((segment) => (
                              <span key={segment} className="selected-segment">
                                {segment}
                                <button
                                  type="button"
                                  className="remove-segment-button"
                                  onClick={() => handleRemoveSegment(segment)}
                                >
                                  &times;
                                </button>
                              </span>
                            ))}
                          </div>
                          <p style={{ fontSize: '12px', color: '#d23333' }}>
                            {companyInfoValidationResult?.segments?.length > 0 ? `${companyInfoValidationResult?.segments}` : ''}
                          </p>
                        </Form.Group>

                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='delegateName' data-fluent-validation-group='company-delegate'></div>
                          <div className="input-container">
                            <img className='icons' src={userIcon} />
                            <Form.Control
                              type="text"
                              placeholder='Firma Temsilcisi Adı*'
                              name="delegateName"
                              value={formData?.delegate.delegateName}
                              onChange={handleChange}

                            />
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="Firma Temsilcisi Adı"
                              required
                              placeholder='Firma Temsilcisi Adı'
                              variant="outlined"
                              name="delegateName"
                              fullWidth
                              style={{ marginBottom: '24px' }}
                              value={formData?.delegate?.delegateName !== undefined ? formData?.delegate?.delegateName : ''}
                              onChange={handleChange}
                              error={delegateInfoValidationResult?.delegateName?.length > 0}
                              helperText={delegateInfoValidationResult?.delegateName}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={userIcon} />
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>
                        </Form.Group>
                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='delegateSurname' data-fluent-validation-group='company-delegate'></div>
                          <div className="input-container">
                            <img className='icons' src={userIcon} />
                            <Form.Control
                              type="text"
                              placeholder='Firma Temsilcisi Soyadı*'
                              name="delegateSurname"
                              value={formData?.delegate.delegateSurname}
                              onChange={handleChange}

                            />
                          </div> */}
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="Firma Temsilcisi Soyadı"
                              required
                              placeholder='Firma Temsilcisi Soyadı'
                              variant="outlined"
                              name="delegateSurname"
                              fullWidth
                              style={{ marginBottom: '24px' }}
                              value={formData?.delegate?.delegateSurname !== undefined ? formData?.delegate?.delegateSurname : ''}
                              onChange={handleChange}
                              error={delegateInfoValidationResult?.delegateSurname?.length > 0}
                              helperText={delegateInfoValidationResult?.delegateSurname}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={userIcon} />
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>

                        </Form.Group>
                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='delegatePosition' data-fluent-validation-group='company-delegate'></div>
                          <div className="input-container">
                            <img className='icons' src={userIcon} />
                            <Form.Control
                              type="text"
                              placeholder='Firma Temsilcisi Pozisyonu'
                              name="delegatePosition"
                              value={formData?.delegate.delegatePosition}
                              onChange={handleChange}

                            />
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="Firma Temsilcisi Pozisyonu"
                              placeholder='Firma Temsilcisi Pozisyonu'
                              name="delegatePosition"
                              variant="outlined"
                              fullWidth
                              style={{ marginBottom: '24px' }}
                              value={formData?.delegate?.delegatePosition}
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={userIcon} />
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>
                        </Form.Group>
                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='phoneNumber' data-fluent-validation-group='create-company-request'></div>
                          <div className="input-container">
                            <img className='icons' src={phoneIcon} />
                            <Form.Control
                              type="tel"
                              placeholder='İşletme Sabit Telefon Numarası'
                              name="phoneNumber"
                              value={formData?.phoneNumber}
                              onChange={handleChange}

                            />
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="İşletme Sabit Telefon Numarası"
                              placeholder='İşletme Sabit Telefon Numarası'
                              variant="outlined"
                              name="phoneNumber"
                              fullWidth
                              type='tel'
                              style={{ marginBottom: '24px' }}
                              value={formData?.phoneNumber !== undefined ? formData?.phoneNumber : ''}
                              onChange={handleChange}

                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={phoneIcon} />
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>
                        </Form.Group>
                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='ownerPhoneNumber' data-fluent-validation-group='create-company-request'></div>
                          <div className="input-container">
                            <img className='icons' src={mobileAddIcon} />
                            <Form.Control
                              type="tel"
                              placeholder='İşletme Cep Telefonu Numarası*'
                              name="ownerPhoneNumber"
                              value={formData?.ownerPhoneNumber}
                              onChange={handleChange}

                            />
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="İşletme Cep Telefonu Numarası"
                              error={companyInfoValidationResult?.ownerPhoneNumber?.length > 0}
                              helperText={companyInfoValidationResult?.ownerPhoneNumber}
                              required
                              placeholder='İşletme Cep Telefonu Numarası'
                              variant="outlined"
                              name="ownerPhoneNumber"
                              fullWidth
                              type='tel'
                              style={{ marginBottom: '24px' }}
                              value={formData?.ownerPhoneNumber !== undefined ? formData?.ownerPhoneNumber : ''}
                              onChange={handleChange}

                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={mobileAddIcon} />
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>
                        </Form.Group>

                        <Form.Group style={{ width: '90%' }} controlId="inputArea">
                          <div className="invalid-feedback" data-fluent-validation-key='workingHours' data-fluent-validation-group='create-company-request'></div>
                          <div>
                            <div className='offer-3-buttons-container' style={{ marginBottom: '54px' }}>
                              <div className='offer-3-select-time-container'>
                                <div style={{ position: 'relative', marginRight: '15px' }}>
                                  <label htmlFor="startTime" style={{ marginBottom: '5px', display: 'block', fontSize: '12px', color: '#9b9a9d' }}>Restoran Açılış</label>
                                  <img
                                    src={timerIcon}
                                    alt="icon"
                                    style={{ position: 'absolute', left: '10px', top: '50%', pointerEvents: 'none', width: '20px' }}
                                  />
                                  <select style={{
                                    width: '415px',
                                    color: '#1D1E25',
                                    fontWeight: '300',
                                    paddingLeft: '35px',
                                    WebkitAppearance: 'none',
                                  }} value={CreateCompanyRequestFactory.extractWorkingHour(company?.workingHours, WorkingHoursType.start) ?? ''} onChange={handleStartTimeChange}>
                                    <option value="">   {
                                      formData?.workingHours
                                        ? CreateCompanyRequestFactory.extractWorkingHour(formData.workingHours, WorkingHoursType.start)
                                        : (company?.workingHours
                                          ? CreateCompanyRequestFactory.extractWorkingHour(company.workingHours, WorkingHoursType.start)
                                          : 'Restoran Açılış*')
                                    }</option>
                                    {hoursOptions.map((hour) => (
                                      <option key={hour} value={hour}>{hour}</option>
                                    ))}

                                  </select>
                                </div>
                                <div style={{ position: 'relative', marginRight: '25px' }}>
                                  <label htmlFor="endTime" style={{ marginBottom: '5px', display: 'block', fontSize: '12px', color: '#9b9a9d' }}>Restoran Kapanış</label>
                                  <img
                                    src={timerIcon}
                                    alt="icon"
                                    style={{ position: 'absolute', left: '10px', top: '50%', pointerEvents: 'none', width: '20px' }}
                                  />
                                  <select style={{
                                    width: '415px',
                                    color: '#1D1E25',
                                    fontWeight: '300',
                                    paddingLeft: '35px',
                                    WebkitAppearance: 'none',
                                  }} value={CreateCompanyRequestFactory.extractWorkingHour(company?.workingHours, WorkingHoursType.end) ?? ''} onChange={handleEndTimeChange}>
                                    <option value="">{
                                      formData?.workingHours
                                        ? CreateCompanyRequestFactory.extractWorkingHour(formData.workingHours, WorkingHoursType.end)
                                        : (company?.workingHours
                                          ? CreateCompanyRequestFactory.extractWorkingHour(company.workingHours, WorkingHoursType.end)
                                          : 'Restoran Kapanış*')
                                    }</option>
                                    {hoursOptions.map((hour) => (
                                      <option key={hour} value={hour}>{hour}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>

                          </div>
                          <p style={{ marginTop: '-20px', fontSize: '12px', color: '#d23333' }}>
                            {companyInfoValidationResult?.workingHours?.length > 0 ? `${companyInfoValidationResult?.workingHours}` : ''}
                          </p>
                        </Form.Group>
                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='capacity' data-fluent-validation-group='create-company-request'></div>
                          <div className="input-container">
                            <img className='icons' src={versionIcon} />
                            <Form.Control
                              type="number"
                              placeholder={'İşletme Kapasitesi'}
                              name="capacity"
                              value={formData?.capacity != undefined ? formData?.capacity : ''}
                              onChange={handleChange}

                            />
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="İşletme Kapasitesi"
                              error={companyInfoValidationResult?.capacity?.length > 0}
                              helperText={companyInfoValidationResult?.capacity}
                              required
                              placeholder='İşletme Kapasitesi'
                              name="capacity"
                              variant="outlined"
                              inputMode="numeric"
                              fullWidth
                              type='tel'
                              style={{ marginBottom: '24px' }}
                              value={formData?.capacity != undefined ? formData?.capacity : ''}
                              onChange={handleChange}

                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={versionIcon} />
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>
                        </Form.Group>
                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='webSiteLink' data-fluent-validation-group='create-company-request'></div>
                          <div className="input-container website-link">
                            <p className='icons' >Link: </p>
                            <Form.Control
                              type="text"
                              placeholder='Web Site Linki'
                              name="webSiteLink"
                              value={formData?.webSiteLink}
                              onChange={handleChange}

                            />
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="Web Site Linki"
                              placeholder='Web Site Linki'
                              variant="outlined"
                              name="webSiteLink"
                              fullWidth
                              type='tel'
                              style={{ marginBottom: '24px' }}
                              value={formData?.webSiteLink}
                              onChange={handleChange}

                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={versionIcon} />
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>
                        </Form.Group>

                        {formData?.logoUrl ? (
                          <Form.Group controlId="inputArea">
                            <div className="invalid-feedback" data-fluent-validation-key='logoUrl' data-fluent-validation-group='create-company-request'></div>
                            <div style={{ justifyContent: 'unset', marginBottom: '0px' }} className='uploadImage' onClick={() => inputRef?.current?.click()}>
                              <div>
                                <img className='uploadImageIcon' style={{ width: '60px', height: '60px', padding: '0px' }} src={`${cdnBaseUrl}${formData?.logoUrl}`} />
                              </div>
                              <input
                                ref={inputRef}

                                style={{ display: 'none' }}
                                className='uploadImageInput'
                                type="file"
                                accept=".jpg,.jpeg,.png"
                                onChange={handleLogoUrlChange}
                              />
                              <label style={{ color: '#89cda2', fontWeight: '700', fontSize: '16px' }}>İşletme Logosu Yüklendi ✅</label>
                            </div>
                          </Form.Group>


                        ) : (
                          <Form.Group controlId="inputArea">
                            <div className="invalid-feedback" data-fluent-validation-key='logoUrl' data-fluent-validation-group='create-company-request'></div>
                            <div style={{ justifyContent: 'unset', marginBottom: '0px' }} className='uploadImage' onClick={() => inputRef?.current?.click()}>
                              <div>
                                <img className='uploadImageIcon' src={cameraActionIcon} />
                              </div>
                              <input
                                className='deneme'
                                ref={inputRef}

                                style={{ display: 'none' }}
                                type="file"
                                accept=".jpg,.jpeg,.png"
                                onChange={handleLogoUrlChange}
                              />
                              <label>İşletme Logosu Yükleme Alanı</label>
                            </div>
                          </Form.Group>


                        )

                        }

                        <div className='infoPassword' style={{ marginTop: '8px', marginBottom: '24px' }}>
                          <BsInfoCircle style={{ width: '14px', marginRight: '8px' }} />
                          <p style={{ marginBottom: '0px' }}>Sadece 1 adet işletme logosu yükleyebilirsiniz.</p>
                        </div>


                        {formData?.photoUrl ? (

                          <Form.Group controlId="inputArea">
                            <div className="invalid-feedback" data-fluent-validation-key='photoUrl' data-fluent-validation-group='create-company-request'></div>
                            <div className='uploadImage' style={{ justifyContent: 'unset', marginBottom: '0px' }} onClick={() => inputRef2?.current?.click()}>

                              <div>
                                <img className='uploadImageIcon' style={{ width: '60px', height: '60px', padding: '0px' }} src={`${cdnBaseUrl}${formData?.photoUrl}`} />
                              </div>
                              <input
                                ref={inputRef2}

                                style={{ display: 'none' }}
                                type="file"
                                className='uploadImageInput'
                                accept=".jpg,.jpeg,.png"
                                placeholder='İşletme Fotoğrafları Yükleme Alanı'
                                onChange={handleCompanyPhotos}
                              />
                              <label style={{ color: '#89cda2', fontWeight: '700', fontSize: '16px' }}>İşletme Dükkan Fotoğrafı Yüklendi ✅ </label>
                            </div>
                          </Form.Group>

                        ) : (

                          <Form.Group controlId="inputArea">
                            <div className="invalid-feedback" data-fluent-validation-key='photoUrl' data-fluent-validation-group='create-company-request'></div>
                            <div className='uploadImage' style={{ justifyContent: 'unset', marginBottom: '0px' }} onClick={() => inputRef2?.current?.click()}>
                              <div>
                                <img className='uploadImageIcon' src={cameraActionIcon} />
                              </div>
                              <input
                                ref={inputRef2}

                                style={{ display: 'none' }}
                                type="file"
                                className='uploadImageInput'
                                accept=".jpg,.jpeg,.png"
                                placeholder='İşletme Fotoğrafları Yükleme Alanı'
                                onChange={handleCompanyPhotos}
                              />
                              <label>İşletme Dükkan Fotoğrafı Yükleme Alanı</label>
                            </div>
                          </Form.Group>


                        )

                        }
                        <div className='infoPassword' style={{ marginTop: '8px' }}>
                          <BsInfoCircle style={{ width: '14px', marginRight: '8px' }} />
                          <p style={{ marginBottom: '0px', }}>Sadece 1 adet işletme fotoğrafı yükleyebilirsiniz.</p>
                        </div>

                      </div>
                    ) : ''}


                    {activeFormGroup === formGroupAddressInfo ? (
                      <div >
                        <h6 className='restaurant-update-screen-titles' id='addressPart'>Adres Bilgileri</h6>

                        <Form.Group controlId="inputArea">
                          {/* <div className="input-container ">
                            <img className='icons' src={TRIcon} />
                            <Form.Control
                              style={{ border: '1px solid #F15F50' }}
                              type="text"
                              placeholder='Türkiye'
                              name="ulke"
                              onChange={handleChange}

                              readOnly
                            />
                            <img className='icons' style={{ left: '120px' }} src={lockIcon} />

                            
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name'
                              data-fluent-validation-group='create-company-request'
                              label="Ülke"
                              name="ulke"
                              required
                              select
                              variant="outlined"
                              fullWidth
                              style={{ marginBottom: '24px' }}
                              value="Türkiye"
                              onChange={handleChange}
                              SelectProps={{
                                IconComponent: () => <BsFillCaretDownFill style={{ width: '14px', marginRight: '15px' }} className="dropdown-icon" />
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={TRIcon} />
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF", borderRadius: '8px' }
                              }}
                            >
                              <MenuItem value="Türkiye">Türkiye</MenuItem>
                            </TextField>
                          </Box>

                        </Form.Group>
                        <Form.Group controlId="inputArea">
                          <div className="invalid-feedback" data-fluent-validation-key='city' data-fluent-validation-group='address'></div>
                          <div className='select-container'>
                            <Form.Control
                              as='select'
                              placeholder='İl'
                              name="city"
                              value={selectedCity?.name}
                              onChange={handleCityChange}

                            >
                              <option value="" >İl</option>
                              {cities?.map((option, index) => (
                                <option key={index} value={cities[index].name}>
                                  {option.name}
                                </option>
                              ))}
                            </Form.Control>
                            <BsFillCaretDownFill style={{ width: '13px' }} className="dropdown-icon" />

                          </div>
                          <p style={{ fontSize: '12px', color: '#d23333' }}>
                            {addressInfoValidationResult?.city?.length > 0 ? `${addressInfoValidationResult?.city}` : ''}
                          </p>
                        </Form.Group>
                        <Form.Group controlId="inputArea">
                          <div className="invalid-feedback" data-fluent-validation-key='district' data-fluent-validation-group='address'></div>
                          <div className='select-container'>
                            <Form.Control
                              as='select'
                              name="district"
                              value={selectedDistrict?.name}
                              onChange={handleDistrictChange}

                            >
                              <option value="">İlçe</option>
                              {districts?.map((option, index) => (
                                <option key={index} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                            </Form.Control>
                            <BsFillCaretDownFill style={{ width: '13px' }} className="dropdown-icon" />
                          </div>
                          <p style={{ fontSize: '12px', color: '#d23333' }}>
                            {addressInfoValidationResult?.district?.length > 0 ? `${addressInfoValidationResult?.district}` : ''}
                          </p>
                        </Form.Group>

                        <Form.Group controlId="inputArea">
                          <div className="invalid-feedback" data-fluent-validation-key='neighborhood' data-fluent-validation-group='address'></div>
                          <div className='select-container'>
                            <Form.Control

                              as="select"
                              placeholder='Mahalle'
                              name="neighborhood"
                              value={selectedNeighborhood?.name}
                              onChange={handleNeighborhoodChange}

                            >
                              <option value="">Mahalle</option>
                              {neighborhoods?.map((option, index) => (
                                <option key={index} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                            </Form.Control>
                            <BsFillCaretDownFill style={{ width: '13px' }} className="dropdown-icon" />
                          </div>
                          <p style={{ fontSize: '12px', color: '#d23333' }}>
                            {addressInfoValidationResult?.neighborhood?.length > 0 ? `${addressInfoValidationResult?.neighborhood}` : ''}
                          </p>
                        </Form.Group>

                        <Form.Group controlId="inputArea">
                          <div className="invalid-feedback" data-fluent-validation-key='street' data-fluent-validation-group='address'></div>
                          <div className='select-container'>
                            <Form.Control

                              as="select"
                              placeholder='Cadde/Sokak*'
                              name="street"
                              value={selectedStreet?.name}
                              onChange={handleStreetChange}
                              required
                            >
                              <option value="">Cadde/Sokak*</option>
                              {streets?.map((option, index) => (
                                <option key={index} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                            </Form.Control>
                            <BsFillCaretDownFill style={{ width: '13px' }} className="dropdown-icon" />
                          </div>

                        </Form.Group>
                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='buildingNo' data-fluent-validation-group='address'></div>
                          <div className='select-container'>

                            <Form.Control

                              type="text"
                              placeholder='Bina No'
                              name="buildingNo"
                              value={formData?.address.buildingNo}
                              onChange={handleChange}

                            />
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="Bina No"
                              error={addressInfoValidationResult?.buildingNo?.length > 0}
                              helperText={addressInfoValidationResult?.buildingNo}
                              required
                              name="buildingNo"
                              placeholder='Bina No'
                              variant="outlined"
                              fullWidth
                              type='tel'
                              value={formData?.address.buildingNo}
                              onChange={handleChange}
                            />

                          </Box>
                        </Form.Group>

                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='latitude' data-fluent-validation-group='location'></div>
                          <Form.Control
                            type="text"
                            placeholder="Enlemi girin (Latitude)"
                            name="latitude"
                            value={latitude}
                            onChange={handleLocationChange}

                          /> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='latitude' data-fluent-validation-group='create-company-request'
                              label="Enlemi girin (Latitude)"
                              required
                              placeholder="Enlemi girin (Latitude)"
                              name="latitude"
                              variant="outlined"
                              fullWidth
                              type='tel'
                              value={formData?.location?.latitude}
                              onChange={handleLocationChange}
                            />

                          </Box>
                        </Form.Group>


                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='longitude' data-fluent-validation-group='location'></div>
                          <Form.Control
                            type="text"
                            placeholder="Boylamı girin (Longitude)"
                            name="longitude"
                            value={longitude}
                            onChange={handleLocationChange}

                          /> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='latitude' data-fluent-validation-group='create-company-request'
                              label="Boylamı girin (Longitude)"
                              required
                              placeholder="Boylamı girin (Longitude)"
                              name="longitude"
                              variant="outlined"
                              fullWidth
                              type='text'
                              value={formData?.location?.longitude}
                              onChange={handleLocationChange}
                            />

                          </Box>
                        </Form.Group>


                        <div className='companyRegisterForm' style={{ marginTop: '40px', marginBottom: '40px', backgroundColor: 'transparent', padding: '0px' }}>
                          <h6 className="companyRegisterTitle">Firma Harita Konumu </h6>
                          <div style={{ display: 'flex', justifyContent: 'center', borderRadius: '12px' }}>
                            {latLng ? <GoogleMap lat={latLng.lat} lng={latLng.lng} /> : <GoogleMap lat={41.015137} lng={28.979530} />}
                          </div>

                        </div>

                      </div>




                    ) : ''}



                    {activeFormGroup === formGroupPassWordInfo ? (
                      <div>
                        <h6 className='restaurant-update-screen-titles' id='pass'>Şifre Alanı</h6>
                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='email' data-fluent-validation-group='create-company-request'></div>
                          <div className="input-container">
                            <img className='icons' src={emailIcon} />
                            <Form.Control
                              type="text"
                              placeholder='E-Mail Adresi'
                              name="email"
                              value={formData?.email}
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                              label="E-Mail Adresi"
                              required
                              placeholder='E-Mail Adresi'
                              name="email"
                              variant="outlined"
                              fullWidth
                              type='text'
                              value={formData?.email}
                              onChange={handleChange}
                              error={companyInfoValidationResult?.email?.length > 0}
                              helperText={companyInfoValidationResult?.email}
                            />

                          </Box>

                        </Form.Group>
                        <Form.Group controlId="inputArea">
                          {/* <div className="invalid-feedback" data-fluent-validation-key='password' data-fluent-validation-group='create-company-request'></div>
                          <div className="input-container">
                            <img className='icons' src={keyIcon} />
                            <Form.Control
                              type="text"
                              placeholder='Şifre'
                              name="password"
                              value={formData?.password}
                              onChange={handlePasswordChange}
                              autoComplete="off"
                            />
                          </div> */}

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              label="Şifre"
                              placeholder='Şifre'
                              name="password"
                              variant="outlined"
                              fullWidth
                              autoCapitalize='none'
                              type={showPassword ? "text" : "password"}
                              value={formData?.password}
                              onChange={handlePasswordChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={keyIcon} alt={''} />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <div className="input-group-append">
                                      <button
                                        type="button"
                                        className="btn eyeButton"
                                        onClick={togglePasswordVisibility}
                                      >
                                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                                      </button>
                                    </div>
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>
                        </Form.Group>
                        <div className='infoPassword'>
                          <BsInfoCircle style={{ width: '30px' }} />
                          <p style={{ marginBottom: '0px' }}>Şifreniz en az 6 karakter uzunluğunda olmalıdır.</p>
                        </div>
                        <Form.Group controlId="inputArea">
                          {/* <div className="input-container">
                            <img className='icons' src={keyIcon} />
                            <Form.Control

                              type="text"
                              placeholder='Şifre Tekrarı'
                              name="confirmPassword"
                              value={confirmPassword}
                              onChange={handlePasswordConfirmChange}
                            />
                          </div> */}
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                              label="Şifre Tekrarı"

                              type={showPassword ? "text" : "password"}
                              placeholder='Şifre Tekrarı'
                              name="confirmPassword"
                              value={confirmPassword}
                              variant="outlined"
                              fullWidth
                              autoCapitalize='none'
                              style={{ marginBottom: '24px' }}
                              onChange={handlePasswordConfirmChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={keyIcon} alt={''} />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <div className="input-group-append">
                                      <button
                                        type="button"
                                        className="btn eyeButton"
                                        onClick={togglePasswordVisibility}
                                      >
                                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                                      </button>
                                    </div>
                                  </InputAdornment>
                                ),
                                style: { backgroundColor: "#FFFF" }
                              }}
                            />

                          </Box>
                          {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                        </Form.Group>
                      </div>
                    ) : ''}


                    {activeFormGroup === formGroupAgreementInfo ? '' : (

                      <div>
                        {/* <button onClick={handleOpenModal} className='preview'><FaTimes style={{ marginRight: '5px', color: 'red' }} /> Ön İzleme</button> */}
                        <div>
                          <Modal size='xl'
                            show={modalOpen}
                            onHide={handleCloseModal}
                            overlayClassName='custom-overlay'

                          >
                            <CompanyPreview onClose={handleCloseModal}
                              photoURL={`${formData?.photoUrl}`}
                              logoURL={`${formData?.logoUrl}`}
                              companyName={`${formData?.name}`}
                              address={`${formData?.address.street} ${formData?.address.buildingNo} ${formData?.address.district}`}
                              segments={formData?.segments ?? []}
                            />
                          </Modal>
                        </div>
                        <div className='restoran-update-bottom-buttons-container'>
                          {
                            activeFormGroup === formGroupCompanyInfo || activeFormGroup === formGroupAddressInfo ? (
                              <Button className='preview-button' onClick={handleOpenModal}>

                                <img src={preview} /> Ön İzleme
                              </Button>

                            ) : ''
                          }

                          <Button className='save-changes' type="submit" >

                            <img src={arrow} /> Değişiklikleri Kaydet
                          </Button>
                        </div>

                      </div>
                    )}


                  </Form>


                  {activeFormGroup === formGroupAgreementInfo ? (

                    agreement ? (
                      <div style={{ marginTop: '25px' }}
                        dangerouslySetInnerHTML={{ __html: agreement }}
                      />
                    ) : (
                      <p>Loading...</p>
                    )
                  ) : ''}
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

