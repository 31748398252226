import { cdnBaseUrl } from '../../api/baseUrl'
import { AiOutlineClose } from 'react-icons/ai'
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import StopFlashDealPopup from '../popup/StopFlashDealPopup'
import { MdStop } from 'react-icons/md'


interface Props {
    onClose: () => void
    activeFlashDealInfo : {
        title:string,
        content:string,
        imageUrl:string,
        finishDate: string,
        usageCount: number,
        maxUsageCount:number,
        flashDealType:number,
        id: string
    }
}


export default function FlashOfferDetailsPopup({ onClose,activeFlashDealInfo }: Props)  {
    const bg = require('../images/icons/Sol_color.png')
    const [showStopSessionModal, setShowStopSessionModal] = useState<boolean>(false);

    const handleStopSessionClick = () => {
      setShowStopSessionModal(true);
      console.log('clicked');
  
    };

    const handleCloseStopSessionModal = () => {
      setShowStopSessionModal(false);
    };

    return (
      <div className='custom-overlay'>
        <div  className='row'>
          <div className='col-sm-2 backgroundDiv'>
            <img src={bg} />
  
          </div>
          <div className='col-sm-10' style={{backgroundColor: 'white'}}>
            <div className='stopSession' style={{padding:'15px'}}>
              <h4 style={{fontWeight:'100',padding:'8px'}}>🔥 Aktif Flash Teklifin</h4>
              <button style={{width:'120px',padding:'8px',margin:'5px'}} onClick={onClose}><AiOutlineClose style={{ color: '#F15F50', marginRight: '5px' }} />Kapat</button>
              </div>
              <hr style={{width:'100%'}}/>
              <div style={{paddingLeft:'15px'}}>
                <label style={{color:'#1D1E2599', fontSize:'14px'}}>Başlık</label>
                <p style={{fontSize:'18px',fontWeight:'700'}}>{activeFlashDealInfo.title}</p>
                <label style={{color:'#1D1E2599',fontSize:'14px'}}>Açıklama</label>
                <p>{activeFlashDealInfo.content}</p>
                <label style={{color:'#1D1E2599', fontSize:'14px'}}>Flas Teklifin Bitiş Tarihi</label>
                <p>{activeFlashDealInfo?.finishDate}</p>
                <label style={{color:'#1D1E2599', fontSize:'14px'}}>Kaç Kullanıcı Yararlandı</label>
                {activeFlashDealInfo?.flashDealType === 1 ? (
                          <p style={{ marginRight: '250px' }}>
                            {activeFlashDealInfo?.usageCount === undefined ? `${activeFlashDealInfo.maxUsageCount}/0` : `${activeFlashDealInfo.maxUsageCount}/${activeFlashDealInfo.usageCount}`}
                          </p>
                        ) : (
                          <p style={{ marginRight: '250px' }}>
                            {activeFlashDealInfo?.usageCount === undefined ? '0' : activeFlashDealInfo.usageCount}
                          </p>
                        )}
                <label style={{color:'#1D1E2599',fontSize:'14px'}}>Kampanya Görseli</label><br/>
                <img style={{width:'200px',height:'120px', borderRadius:'8px'}} src={`${cdnBaseUrl}${activeFlashDealInfo.imageUrl}`}/>
                <button style={{ width: '200px', fontSize: '13px', height: '20px', alignItems: 'center', marginTop: '10px', display: 'flex', alignContent: 'center' }} className='stop-session-button' onClick={handleStopSessionClick}><MdStop style={{ color: '#F15F50' }} />Flash Teklifi Durdur</button>
              </div>
              <div className='stop-session-container'>
                <Modal
                  isOpen={showStopSessionModal}
                  onRequestClose={handleCloseStopSessionModal}
                  contentLabel="Stop Session Modal"
                  overlayClassName="custom-overlay stop-session-pop-up"
                >
                  <StopFlashDealPopup onClose={handleCloseStopSessionModal} activeFlashDealInfo={activeFlashDealInfo} />
                </Modal>
              </div>
          </div>
        </div>
      </div>
    )
}
