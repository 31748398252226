
import baseUrl from "../../../api/baseUrl"
import { CreateCampaignRequest } from "../../../model/request/CreateCampaignRequest"
import { CampaignDTO } from "../../../model/dto/CampaignDTO"
import instance from "../../../api/interceptor"
import { CompanyLoginResultDTO } from "@/model/dto/CompanyLoginResultDTO"
import { LoginRequest } from "@/model/request/LoginRequest"
import { QRCodeDTO } from "../../../model/dto/QRCodeDTO"
import { CreateDocumentRequest } from "@/model/request/CreateDocumentRequest"
import { CityDTO } from "@/model/dto/CityDTO"
import { CompanyDTO } from "@/model/dto/CompanyDTO"
import { CreateCompanyRequest } from "@/model/request/CreateCompanyRequest"
import { CampaignDataRequest } from "@/model/request/CampaignDataRequest"
import { NotificationDataRequest } from "@/model/request/NotificationDataRequest"
import { NotificationDTO } from "@/model/dto/NotificationDTO"
import { DocumentDTO } from "@/model/dto/DocumentDTO"
import { DistrictDTO } from "@/model/dto/DistrictDTO"
import { NeighborhoodDTO } from "@/model/dto/NeighborhoodDTO"
import { StreetDTO } from "@/model/dto/StreetDTO"
import { DocumentUrlDTO } from "@/model/dto/DocumentUrlDTO"
import { ImageDTO } from "@/model/dto/ImageDTO"
import { GuestDTO } from "@/model/dto/GuestDTO"
import {GuestLoginResultDTO} from "@/model/dto/GuestLoginResultDTO";
import {VersionsDTO} from "@/model/dto/VersionsDTO";
import axios from "axios";
import { CreateCompanyAgreementRequest } from "@/model/request/CreateCompanyAgreement"
import { SendRecoverPasswordCodeRequest } from "@/model/request/SendRecoverPasswordCodeRequest"


class ApiService {

    static async createFlashDeal(requestData: CreateCampaignRequest): Promise<CampaignDTO | null> {
        const options = {
            method: 'POST',
            url: `${baseUrl}campaigns`,
            data: requestData
        }
        try {
            const response = await instance.request(options);
            return response.data as CampaignDTO;
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async login(requestData: LoginRequest): Promise<CompanyLoginResultDTO | null> {
        const options = {
            method: 'POST',
            url: `${baseUrl}companies/login`,
            data: requestData
        }
        try {
            const response = await instance.request(options);
            return response.data as CompanyLoginResultDTO;
        } catch (error) {
            console.log(error);
            return null

        }
    }

    static async getCampaignQRCodesByStatus(campaignId: string, companyId: string, status: string): Promise <[QRCodeDTO] | null>{
        const options = {
            method: 'GET',
            url: `${baseUrl}qr-codes/campaigns/${campaignId}`,
            params: { status: status },
            userId: companyId
        }
        try {
            const response = await instance.request(options);
            return response.data as [QRCodeDTO];
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async getQRCodeByCode(code: String): Promise<QRCodeDTO | null> {
        const options = {
            method: 'GET',
            url: `${baseUrl}qr-codes/code/${code}`,
        }
        try {
            const response = await instance.request(options);
            return response.data as QRCodeDTO
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async getQRCodeById(qrCodeId: String): Promise<QRCodeDTO | null> {
        const options = {
            method: 'GET',
            url: `${baseUrl}/qr-codes/${qrCodeId}`,
        }
        try {
            const response = await instance.request(options);
            return response.data as QRCodeDTO
        } catch (error) {
            console.log(error);
            return null
        }
    };

    static async consumeQRCode(code: String): Promise<QRCodeDTO | null> {
        const options = {
            method: 'PUT',
            url: `${baseUrl}qr-codes/consume`,
            params: { code },
        }
        try {
            const response = await instance.request(options);
            return response.data as QRCodeDTO
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async sendNotification(notificationData: NotificationDataRequest): Promise<NotificationDTO | null> {
        const options = {
            method: 'POST',
            url: `${baseUrl}notifications`,
            data: notificationData
        }
        try {
            const response = await instance.request(options);
            return response.data as NotificationDTO
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async getNotifications(): Promise<[NotificationDTO] | null> {
        const options = {
            method: 'GET',
            url: `${baseUrl}notifications/admin?offset=0&count=40`,
        }
        try {
            const response = await instance.request(options);
            return response.data as [NotificationDTO]
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async fetchAppDocuments():Promise <[DocumentDTO] | null> {
        const options = {
            method: 'GET',
            url: `${baseUrl}app-documents`,
        }
        try {
            const response = await instance.request(options);
            return response.data as [DocumentDTO]
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async createAppDocument(request: CreateDocumentRequest): Promise <boolean> {
        const options = {
            method: 'POST',
            url: `${baseUrl}app-documents`,
            data: request
        }
        try {
            const response = await instance.request(options);
            if (response.status >=200 && response.status<300){
                return true
            }else {
                return false
            }
        } catch (error) {
            console.log(error);
            return false
        }
    }

    static async deleteAppDocuments(documentCode: String): Promise <boolean> {
        const options = {
            method: 'DELETE',
            url: `${baseUrl}app-documents/${documentCode}`,
        }
        try {
            const response = await instance.request(options);
            if (response.status >= 200 && response.status < 300) {
                return true
            }else {
                return false
            }
        } catch (error) {
            console.log(error);
            return false
        }
    }

    static async updateAppDocument(documentData: any):Promise <DocumentDTO | null >{
        const options = {
            method: 'PUT',
            url: `${baseUrl}app-documents`,
            data: documentData

        }
        try {
            const response = await instance.request(options);
            return response.data as DocumentDTO
        } catch (error) {
            console.log(error);
            return null
        }

    }

    static async getCities(): Promise<[CityDTO] | null> {
        const options = {
            method: 'GET',
            url: `${baseUrl}company/locations/cities`,
        }
        try {
            const response = await instance.request(options);
            return response.data as [CityDTO]
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async getDistrictById(cityId: String): Promise <[DistrictDTO] | null > {
        const options = {
            method: 'GET',
            url: `${baseUrl}company/locations/cities/${cityId}/districts`,
        }
        try {
            const response = await instance.request(options);
            return response.data as [DistrictDTO]
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async getNeighboorhoodDistrictById(districtId: String): Promise <[NeighborhoodDTO] | null> {
        const options = {
            method: 'GET',
            url: `${baseUrl}company/locations/districts/${districtId}/neighborhoods`,
        }
        try {
            const response = await instance.request(options);
            return response.data as [NeighborhoodDTO]
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async getStreetsById(neighboorhoodId: String): Promise <[StreetDTO]| null> {
        const options = {
            method: 'GET',
            url: `${baseUrl}company/locations/neighborhoods/${neighboorhoodId}/streets`,
        }
        try {
            const response = await instance.request(options);
            return response.data
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async getCompany(companyId: String): Promise<CompanyDTO | null> {
        const options = {
            method: 'GET',
            url: `${baseUrl}companies/${companyId}`,
        }
        try {
            const response = await instance.request(options);
            return response.data as CompanyDTO
        } catch (error) {
            console.log(error);
            return null
        }
    }


    static async registerCompany(companyData: CreateCompanyRequest): Promise<CompanyDTO | null> {
        const options = {
            method: 'POST',
            url: `${baseUrl}companies/register`,
            data: companyData

        }
        try {
            const response = await instance.request(options);
            return response.data as CompanyDTO
        } catch (error) {
          throw error
        }
    }

    static async createCampaign(campaignData: CampaignDataRequest):Promise <CampaignDTO | null> {
        const options = {
            method: 'POST',
            url: `${baseUrl}campaigns`,
            data: campaignData

        }
        try {
            const response = await instance.request(options);
            return response.data as CampaignDTO
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async updateCompany(companyData: CreateCompanyRequest, companyId: String): Promise <CompanyDTO | null > {
        const options = {
            method: 'PUT',
            url: `${baseUrl}companies/${companyId}`,
            data: companyData

        }
        try {
            const response = await instance.request(options);
            return response.data as CompanyDTO
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async getCampaignsByCompanyId(companyId: String): Promise <[CampaignDTO] | null > {
        const options = {
            method: 'GET',
            url: `${baseUrl}campaigns/company/${companyId}`,
        }
        try {
            const response = await instance.request(options);
            return response.data as [CampaignDTO]
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async getCampaignsByCityId(cityId: String): Promise<[CampaignDTO] | null> {
        const options = {
            method: 'GET',
            url: `${baseUrl}campaigns/city`,
            params: { city: cityId },
        }
        try {
            const response = await instance.request(options);
            return response.data as [CampaignDTO]
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    static async PutCampaignsFinish(campaignId: String): Promise <boolean> {
        const options = {
            method: 'PUT',
            url: `${baseUrl}campaigns/finish/${campaignId}`,
        }
        try {
            const response = await instance.request(options);
            if (response.status >= 200 && response.status < 300){
                return true
            }else{
                return false
            }

        } catch (error) {
            console.log(error);
            return false
        }
    }

    static async PutProlong(campaignId: String, selectedValue: String): Promise <boolean> {
        const options = {
            method: 'PUT',
            url: `${baseUrl}campaigns/prolong/${campaignId}`,
            params: { finishTime: selectedValue },
        }
        try {
            const response = await instance.request(options);
            if (response.status >= 200 && response.status < 300){
                return true
            }else{
                return false
            }
        } catch (error) {
            console.log(error);
            return false
        }
    }

    static async uploadFile(file: File): Promise<ImageDTO | null> {
        const formData = new FormData();
        formData.append('image', file);
        const options = {
            method: 'POST',
            url: `${baseUrl}media/images`,
            data:formData,
            headers : {
                'Content-Type': 'multipart/form-data'
            }
        }
        try {
            const response = await instance.request(options);
            return response.data 
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async getImage(imageUrl: string) {
        const trimmedImageUrl = imageUrl.split('/').pop();
        const options = {
            method: 'GET',
            url: `${baseUrl}media/images/${trimmedImageUrl}`,
            params: { Width: '50' },
        }
        try {
            const response = await instance.request(options);
            return response.data 
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async uploadDocument(file: File): Promise<DocumentUrlDTO | null> {
        const formData = new FormData();
        formData.append('file', file);
        const options = {
            method: 'POST',
            url: `${baseUrl}media/documents`,
        }
        try {
            const response = await instance.request(options);
            return response.data as DocumentUrlDTO
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async sendRecoveryCode(request: SendRecoverPasswordCodeRequest):Promise<boolean> {
        const options = {
            method: 'POST',
            url: `${baseUrl}companies/recovery/send-recovery-code`,
            data: request
        }
        try {
            const response = await instance.request(options);
            if(response.status >=200 && response.status < 300){
                return true
            }else {
                return false
            }
        } catch (error) {
            console.log(error);
            return false
        }
    }

    static async fetchRepoVersions() : Promise<VersionsDTO | null> {
        const options = {
            method : 'GET',
            url: `${baseUrl}version/app`,
        }
        try {
            const response = await instance.request(options);
            return response.data as VersionsDTO
        }
        catch (error) {
            return null
        }
    }

    static async verifyRecoveryCode(email: string, code: string, newPassword: string): Promise<boolean> {
        const options = {
            method: 'POST',
            url: `${baseUrl}companies/recovery/recover-password`,
            data: { email, code, newPassword }

        }
        try {
            const response = await instance.request(options);
            if (response.status === 204 || response.status === 200) {
                return true
            }
            return false
        } catch (error) {
            console.log(error);
            return false
        }
    }

    static async sendSmsCode(phoneNumber: string): Promise<boolean | null> {
        const options = {
            method: 'POST',
            url: `${baseUrl}users/sms-code/send`,
            data: { phoneNumber, purpose: 1 }

        }
        try {
            const response = await instance.request(options);
            return response.data as boolean
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async verifySmsCode(phoneNumber: string, code: string): Promise<boolean | null> {
        const options = {
            method: 'POST',
            url: `${baseUrl}users/sms-code/verify-code-common`,
            data: { phoneNumber, code, purpose: 1 }
        }
        try {
            const response = await instance.request(options);
            return response.data as boolean
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async deleteAccount(phoneNumber: string, code: string): Promise<number | string | any | null> {
        const options = {
            method: 'DELETE',
            url: `${baseUrl}users/delete-account`,
            data: { phoneNumber, code }
        }
        try {
            const response = await instance.request(options);
            return response.data
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async postInAppContent(content: string): Promise<string | null> {
        const options = {
            method: 'POST',
            url: `${baseUrl}in-app-content`,
            data: { content }
        }
        try {
            const response = await instance.request(options);
            return response.data
        } catch (error) {
            console.log(error);
            return null
        }
    }

    static async createGuest (): Promise <GuestDTO | null > {
        const options = {
            method : 'POST',
            url: `${baseUrl}users/guests`,
        }
        try {
            const response = await instance.request(options)
            return response.data as GuestDTO
        } catch (error) {
            return null
        }
    }

    static async loginGuest (): Promise<GuestLoginResultDTO | null> {
        const options = {
            method: 'POST',
            url: `${baseUrl}users/guests/login`,
        }
        try {
            const response = await instance.request(options)
            return response.data as GuestLoginResultDTO
        }
        catch (error) {
            return null
        }
    }



    static async refreshToken(refreshToken:string) : Promise<CompanyLoginResultDTO | null> {

        const options = {
            url: `${baseUrl}companies/refresh-token`,
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: {
                refreshToken: refreshToken
            }
        }

        const response = await axios.request(options)
        return response.data as CompanyLoginResultDTO
    }


    static async logout (sessionId: string): Promise<boolean> {
        const options = {
            method: 'POST',
            url: `${baseUrl}companies/logout`,
            headers: {
                'content-type': 'application/json',
                sessionId: sessionId
            }

        }
        await axios.request(options)

        return true
    }


    static async postCompanyAgreement (aggrement:string, companyId:string): Promise <boolean | null> {
        const options = {
            method: 'POST',
            url:`${baseUrl}companies/agreement`,
            data: {aggrement,companyId}
        }
        try {
            const response = await axios.request(options);
            return response.data as boolean
        } catch (error) {
            return null
        }
    }

    static async getCompanyAgreement (companyId:string): Promise <string | null> {
        const options={
            method: 'GET',
            url:`${baseUrl}companies/agreement/${companyId}`,
        }
        try {
            const response = await instance.request(options);
            return response.data 
        } catch (error) {
            return null
        }
    }
}

export default ApiService