import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import '../styles/style.css'
import { Modal } from 'react-bootstrap';
import QRConsumeResponsePopup from './QRConsumeResponsePopup';
import { Repository } from '../../data/repository/repository';

interface Props {
    onClose: () => void,
    campaignId: string
}

export default function QRCodeConsume({ onClose, campaignId }: Props) {
    const qr = require('../images/icons/Qr-codeBig.png');
    const arrow = require('../images/icons/Text--new-line.png')
    const [showModal, setShowModal] = useState<boolean>(false);
    const [code, setCode] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [characterCount, setCharacterCount] = useState<number>(0);
    const [qrCodeDetails, setQrCodeDetails] = useState<any>(null);
    const [showQRCodeSection, setShowQRCodeSection] = useState<boolean>(true);

    const submitQRCode = async () => {
        try {
            await Repository.consumeQrCode(code);
            const details = await Repository.getQrCodeByCode(code);
            setQrCodeDetails(details);
            setShowModal(true);
            setErrorMessage('');
            setShowQRCodeSection(false);

        } catch (error: any) {
            if (error.response && error.response.status >= 400 && error.response.status < 500) {
                setErrorMessage('Hatalı Kod. Lütfen kod numarasını kontrol ediniz.');
            } else {
                console.error('Beklenmeyen bir hata oluştu:', error.message);
            }
        }
    }


    // const showSuccessModal = () => {
    //     setShowModal(true)
    // }

    const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.toUpperCase();

        if (value.length === 5 && !value.includes('-')) {
            value = value.slice(0, 4) + '-' + value.slice(4);
        }

        if (value.length <= 9) {
            setCode(value);
        }
    };
   

    const handleCloseAll = () => {
        setShowModal(false);
        setShowQRCodeSection(true);
        onClose();
    };

    const handleRestart = () => {
        setCode('');
        setErrorMessage('');
        setQrCodeDetails(null);
        setShowModal(false);
        setShowQRCodeSection(true);
    };


    useEffect(() => {
        setCharacterCount(code.length);
    }, [code]);

    return (
        <div style={{ padding: '25px' }}>
            {showQRCodeSection && (
                <>
                    <div className='stopSession'>
                        <img src={qr} style={{ width: '100px' }} alt="QR Code" />
                        <button onClick={onClose}><AiOutlineClose style={{ color: '#F15F50', marginRight: '5px' }} />Kapat</button>
                    </div>
                    <div className='content'>
                        <h5>QR Kod Okut</h5>
                        <p className='stopSessionFirstP'>Müşterinizin telefonunda görünen QR Kod’u bilgisayarınıza bağlı bir barkot okuyucu okutup ilgili kod alanını otomatik doldurabilirsiniz.</p>
                        <p style={{ color: '#2F9461' }}>
                            Eğer bir barkot okuyucunuz yoksa QR Kod’u manuel olarak klavyeden girebilirsiniz.
                        </p>
                    </div>
                    <hr style={{ width: '102%', marginBottom: '50px' }} />
                    <div>
                        <h5 className='rUSure'>QR Kodun Manuel Karşılığı:</h5>
                        {errorMessage ? (
                            <input style={{ width: '102%', height: '82px', borderRadius: '12px', border: '3px dashed #CD3636', fontSize: '40px', fontWeight: '400' }}
                                value={code}
                                maxLength={9}
                                onChange={handleCodeChange}
                            />
                        ) : (
                            <input style={{ width: '102%', height: '82px', borderRadius: '12px', border: '3px dashed #1D1E25', fontSize: '40px', fontWeight: '400' }}
                                value={code}
                                maxLength={9}
                                onChange={handleCodeChange}

                            />
                        )

                        }

                        <p style={{ textAlign: 'right', marginTop: '-25px', color: '#1D1E2599', fontSize: '14px' }}>{characterCount}/9</p>
                    </div>
                    <hr style={{ width: '102%', marginBottom: '50px' }} />
                    {errorMessage && <p style={{ color: '#CD3636', fontSize: '20px' }}>{errorMessage}</p>}

                    <div className='confirmButtons'>
                        <button onClick={submitQRCode} className='confirmButtonNo' disabled={code.length !== 9}>
                            <img src={arrow} alt="Arrow" /> Onayla
                        </button>
                    </div>

                </>
            )}

            <Modal
                show={showModal}
                onHide={handleCloseAll}
                contentLabel="Thanks Pop-up"
                overlayClassName="custom-overlay"
            >
                <QRConsumeResponsePopup onClose={handleCloseAll} onRestart={handleRestart} code={code} details={qrCodeDetails} />
            </Modal>

        </div>
    )
}
