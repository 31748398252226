import { Validator } from 'fluentvalidation-ts';
export interface Address {
    city: string
    district: string | null
    neighborhood: string | null
    street: string | null
    buildingNo: string | null
  }

export class AddressValidator extends Validator<Address> {
  constructor() {
    super();

    this.ruleFor('district')
      .notNull()
      .withMessage('Lütfen ilçe seçiniz')
      .notEmpty()
      .withMessage('Lütfen ilçe seçiniz');
      
    this.ruleFor('neighborhood')
      .notNull()
      .withMessage('Lütfen mahalle seçiniz')
      .notEmpty()
      .withMessage('Lütfen mahalle seçiniz');

    this.ruleFor('street')
      .notNull()
      .withMessage('Lütfen cadde/sokak giriniz')
      .notEmpty()
      .withMessage('Lütfen cadde/sokak giriniz');

    this.ruleFor('buildingNo')
      .notNull()
      .withMessage('Lütfen bina numarası giriniz')
      .notEmpty()
      .withMessage('Lütfen bina numarası giriniz');
  }
}
  