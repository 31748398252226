import { Address } from "../common/Address"

export interface CampaignDTO {
    id: string
    promotionType: PromotionType
    flashDealType: FlashDealType
    companyId: string
    startDate: string
    finishDate: string
    maxUsageCount: any
    usageCount: number
    discountRate: number
    agreementUrl: string
    isActive: boolean
    companyMeta: CompanyMeta
    title: string
    content: string
    imageUrl: string


  }
  
  export interface CompanyMeta {
    name: string
    address: Address
    labels: any[]
    photoUrl: string
    logoUrl: string
    location: Location
  }
  

  export interface Location {
    longitude: number
    latitude: number
  }


  export enum PromotionType {
    discount = 0,
    flashDeal = 1,
  }

  export enum FlashDealType {
    manuel = 0,
    usageBased = 1,
    timeBased = 2,
  }