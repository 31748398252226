import React, { useEffect, useState } from 'react'
import '../styles/style.css'
import { AiOutlineClose } from 'react-icons/ai';
import { Checkbox } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CampaignDTO } from '@/model/dto/CampaignDTO';
import moment, { now } from 'moment';
import baseUrl, { cdnBaseUrl } from '../../api/baseUrl';
import { Repository } from '../../data/repository/repository';
import { DocumentDTO } from '@/model/dto/DocumentDTO';
interface Props {
    onClose: () => void
    activeFlashDealInfo: {
        title: string,
        content: string,
        imageUrl: string,
        finishDate: string,
        usageCount: number,
        id: string
    }
}


export default function StopFlashDealPopup({ onClose, activeFlashDealInfo }: Props) {

    const warning = require('../images/icons/Warning--hex.png');
    const arrow = require('../images/icons/Text--new-line.png')

    const [documentUrls, setDocumentUrls] = useState({ agreementDocumentUrl: '', privacyDocumentUrl: '', termsDocumentUrl: '' });


    const navigate = useNavigate();
    const [activeQrCount, setActiveQrCount] = useState(0);

    const [aggrementCheckbox, setAggrementCheckbox] = useState<boolean>(false);





    const handleAggrementCheckboxValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAggrementCheckbox(event.target.checked);
    };

    useEffect(() => {
        Repository.getAppDocuments().then(() =>
            setDocumentUrls({
                agreementDocumentUrl: Repository.getAppDocumentUrl('A2'),
                privacyDocumentUrl: Repository.getAppDocumentUrl('P101P'),
                termsDocumentUrl: Repository.getAppDocumentUrl('A1')
            })
        )
    }, []);

    useEffect(() => {
        fetchCampaignsQrCodes();
    }, []);

    const fetchCampaignsQrCodes = async () => {
        try {
            const qrCodes = await Repository.getQrCodeByCampaignId(activeFlashDealInfo!.id, Repository.getMyCompany()?.id ?? '', '0');
            const activeQrCodes = qrCodes?.filter((qr: { status: number }) => qr.status === 0) ?? [];
            setActiveQrCount(activeQrCodes.length);
        } catch (error) {
            console.error(error);
        }
    };

    const handleStopSession = async () => {
        try {

            if (aggrementCheckbox === false) {
                toast.error('Lütfen kullanım koşullarını onaylayınız!');
                return;
            }

            const success = await Repository.PutCampaignsFinish(activeFlashDealInfo!.id);
            if (success) {
                toast.success('Kampanya Durduruldu!');
                onClose();
                window.location.reload()
            } else {
                toast.error('Kampanya Durdurma Başarısız!');
            }
        } catch (error) {
            toast.error('Bir Hata Meydana Geldi!');
        }
    };




    return (
        <div className='custom-overlay'>
            <div className="popup-content">
                <div className='stopSession'>
                    <img src={warning} style={{ width: '100px' }} />
                    <button onClick={onClose}><AiOutlineClose style={{ color: '#F15F50', marginRight: '5px' }} />Vazgeç</button>
                </div>
                <div className='content'>
                    <h5>“Flash Teklifi” Durdurmak Üzerisin.</h5>
                    <p className='stopSessionFirstP'>Şu anda aktif bir teklifi kapatmak üzere olduğunuzu hatırlatırız.</p>
                    <p className='stopSessionSecondP'>Teklifi kapattıktan sonra, restoranınıza yola çıkmış kullanıcılar, sizden aldıkları QR kodlarını kullanarak indirimlerini talep etmeye devam edebilirler.</p>
                    <p className='qrCount'>Aktif QR Sayısı: {activeQrCount}</p>
                </div>
                <hr style={{ width: '780px', marginBottom: '50px' }} />
                <div>
                    <h5 className='rUSure'>Teklifi kapatmak istediğinizden emin misiniz?</h5>
                </div>
                <hr style={{ width: '780px', marginBottom: '50px' }} />

                <div className='contract'>
                    <Checkbox onChange={handleAggrementCheckboxValueChanged}></Checkbox>
                    <p>Devam ederek, <a href={`${documentUrls.termsDocumentUrl}`} target='_blank'>Kullanım Koşullarını</a>, <a href={`${documentUrls.agreementDocumentUrl}`} target='_blank'>Kullanıcı Sözleşmelerini</a> ve <a href={`${documentUrls.privacyDocumentUrl}`} target='_blank'>Gizlilik Politikasını</a> kabul etmiş olursunuz.</p>
                    
                </div>
                <div className='confirmButtons'>
                    <button onClick={onClose} className='confirmButtonNo'><img src={arrow} /> Hayır</button>
                    <button onClick={handleStopSession} className='confirmButtonYes'> Evet</button>

                </div>
            </div>
        </div>
    )
}
