import React, { ReactNode, useEffect, useState } from 'react';
import '../styles/style.css';
import { MdTimer } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import { toast } from 'react-toastify';
import moment from 'moment';
import { CampaignDTO } from '@/model/dto/CampaignDTO';
import baseUrl, { cdnBaseUrl } from '../../api/baseUrl';

import { Repository } from '../../data/repository/repository';
import { DocumentDTO } from '@/model/dto/DocumentDTO';


interface Props {
    onClose: () => void;

}



export default function ExtendSessionPopup({ onClose }: Props) {
    const bg = require('../images/icons/Sol_color-2.png');
    const lock = require('../images/icons/Locked.png');
    const arrow = require('../images/icons/Text--new-line.png');
    const time = require('../images/icons/OrangeTimer.png')
    const [selectedValue, setSelectedValue] = useState<string>('');
    const [aggrementCheckbox, setAggrementCheckbox] = useState<boolean>(false);
    const [campaignId, setCampaignId] = useState('')
    const [remainingSec, setRemainingSec] = useState<number>();
    const [remainingMin, setRemainingMin] = useState<number>(0);
    const [remainingHours, setRemainingHours] = useState<number>(0);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [finishDate, setFinishDate] = useState<Date | null>(null);
    const [discountRate, setDiscountRate] = useState<number | null>();
    const navigate = useNavigate();

    const company = Repository.getMyCompany()
    //const companyId = company?.id || '';

    const [documents, setDocuments] = useState<DocumentDTO[]>([]);
    const [documentUserAgreementUrl, setDocumentUserAgreementUrl] = useState<string>('');
    const [documentPrivacyUrl, setDocumentPrivacyUrl] = useState<string>('');
    const [documentTermsAndConditionsUrl, setDocumentTermsAndConditionsUrl] = useState<string>('');


    useEffect(() => {
        const getDocuments = async () => {
            var result = await Repository.getAppDocuments()
            setDocuments(result ?? [])
        };

        getDocuments();

    }, []);

    useEffect(() => {
        if (documents !== null && documents.length > 0) {
            setDocumentTermsAndConditionsUrl(getDocumentUrlByCode('A1'));
            setDocumentUserAgreementUrl(getDocumentUrlByCode('A2'));
            setDocumentPrivacyUrl(getDocumentUrlByCode('P101P'));
        }
    }, [documents]);



    const getDocumentUrlByCode = (code: string) => {
        try {
            var document = documents.filter(d => d.code === code)[0];

            return cdnBaseUrl + document.documentUrl;
        }
        catch (error) {
            return "";
        }
    }

    const handleButtonClick = (value: string) => {
        setSelectedValue(value);
    };

    useEffect(() => {
        if (finishDate) {
            const intervalId = setInterval(() => {
                const remainingTime = calculateRemainingTime(finishDate);
                setRemainingMin(remainingTime.minutes);
                setRemainingHours(remainingTime.hours);

                if (remainingTime.minutes === 0 && remainingTime.hours === 0) {
                    clearInterval(intervalId);
                }
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [finishDate]);

    const generateButtons = (): ReactNode[] => {
        const buttons: ReactNode[] = [];
        let hour = 14;
        let minute = 0;

        let buttonCount = 48;

        let startGenerateDate = new Date(finishDate!);

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        if (startGenerateDate.getMinutes() < 30) {
            hour = startGenerateDate.getHours();
            minute = 30;
        }
        else {
            hour = startGenerateDate.getHours() + 1;
            minute = 0;
        }

        let a = startGenerateDate.getDay()
        let b = tomorrow.getDay()

        if (a === b) {
            return buttons;
        }

        for (let i = 0; i <= buttonCount; i++) {
            let timeString: string;

            if (hour === 24) {
                timeString = "00:00";
            } else {
                timeString = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            }
            buttons.push(
                <button
                    key={timeString}
                    onClick={() => handleButtonClick(timeString)}
                    className={selectedValue === timeString ? 'selected-button' : 'normal-button'}
                >
                    {timeString} {selectedValue === timeString}
                </button>
            );

            if (hour === 24) {
                break;
            }

            minute += 30;
            if (minute >= 60) {
                minute = 0;
                hour++;
            }
        }

        return buttons;
    };

    const handleLiveRightNow = async () => {

        if (aggrementCheckbox === false) {
            toast.error('Lütfen kullanım koşullarını onaylayınız!');
            return;
        }

        if (selectedValue === '') {
            toast.error('Lütfen bir saat seçiniz!');
            return;
        }

        const selectedTime = selectedValue;
        const [saat, dakika] = selectedTime.split(':');
        const now = new Date();
        now.setHours(parseInt(saat, 10));
        now.setMinutes(parseInt(dakika, 10));

        const formattedTime = (moment(now)).format('YYYY-MM-DDTHH:mm:ss')

        try {
            await Repository.PutProlong(campaignId, formattedTime);
            toast.success('Oturum Süresi Başarıyla Uzatıldı!');
            onClose();
            window.location.reload();

        } catch (error) {
            console.log(error);
            toast.error('Bir Hata Meydana Geldi!');
        }
    };

    const handleAggrementCheckboxValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAggrementCheckbox(event.target.checked);
    };

    const calculateRemainingTime = (finishDate: Date) => {
        try {
            const now = new Date();
            const remainingTimeInSeconds = Math.max(Math.floor((finishDate.getTime() - now.getTime()) / 1000), 0);

            const remainingHours = Math.floor(remainingTimeInSeconds / 3600);
            const remainingMinutes = Math.floor((remainingTimeInSeconds % 3600) / 60);

            return { hours: remainingHours, minutes: remainingMinutes };
        } catch (error) {
            console.error("Error in calculateRemainingTime:", error);
            return { minutes: 0, hours: 0 };
        }
    };


    const fetchCampaigns = async () => {
        try {
            const campaigns = await Repository.getCampaignsByCompanyId(company?.id ?? '');

            const activeCampaigns = campaigns?.filter((obj: CampaignDTO) => {
                const startDate = moment(obj.startDate, "DD/MM/YYYY HH:mm:ss").toDate();
                const finishDate = moment(obj.finishDate, "DD/MM/YYYY HH:mm:ss").toDate();

                return obj.promotionType === 0 && finishDate > new Date() && (obj.isActive || startDate > new Date());
            }) ?? [];

            if (activeCampaigns.length > 0) {
                setCampaignId(activeCampaigns[0].id)
                const activeObjectFinishDate = moment(activeCampaigns[0].finishDate, "DD/MM/YYYY HH:mm:ss").toDate();
                const activeObjectDiscountRate = activeCampaigns[0].discountRate;

                setFinishDate(activeObjectFinishDate);
                setDiscountRate(activeObjectDiscountRate);

                const remainingTime = calculateRemainingTime(activeObjectFinishDate);
                setRemainingMin(remainingTime.minutes);
                setRemainingHours(remainingTime.hours);
            }
        } catch (error) {
            console.error(error, 'campaign not found');
        }
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                fetchCampaigns();
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);
    return (
        <div className="container-fluid custom-overlay">
            <div className="row popup-content" style={{ padding: '0px', width:'85%',maxWidth:'unset' }}>
                <div className="col-sm-2"
                    style={{
                        height: '100%',
                        backgroundImage: `url(${bg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        borderTopLeftRadius: '8px',
                        borderBottomLeftRadius: '8px'
                    }}
                >

                </div>
                <div className="col-sm-10" style={{overflow:'auto'}}>
                    <div className='contentDiv' >
                        <div className='titleLiveRightNov'>
                            <div className='liveRightNov titleOnline stopSession'>
                                <h2  style={{marginTop:'20px',marginLeft:'-20px'}}>
                                    Oturum Uzatma
                                </h2>
                                <button style={{ marginTop: '20px' }} onClick={onClose}><AiOutlineClose style={{ color: '#F15F50', marginRight: '5px' }} />Vazgeç</button>
                            </div>
                            <hr style={{ width: '100%',marginLeft:'-20px' }} />
                        </div>
                        <div className='row upperContent'>
                            <div className='col-sm-5'>
                                <p>Ne kadar daha online kalacağım?</p>
                                <button className='timerButtonExtendSession'><img src={time} style={{ width: '35px' ,marginBottom:'10px',marginRight:'5px'}} /> {remainingHours}<span style={{ color: '#1D1E2533' }}>Sa</span> : {remainingMin}<span style={{ color: '#1D1E2533' }}>Dk</span></button>
                            </div>
                            <div className='col-sm-2' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '88px' }}>
                                <hr style={{ width: '2px', height: '100%', border: 'none', backgroundColor: '#1D1E2533' }} />
                            </div>

                            <div className='col-sm-5'>
                                <p>
                                    Ne zaman kapanacak?
                                </p>
                                <button className='timerButtonExtendSession'>
                                    <img src={lock} style={{ width: '35px' ,marginBottom:'10px',marginRight:'5px'}} />
                                    {finishDate?.toLocaleString([], { hour: '2-digit', minute: '2-digit' })}
                                </button>

                            </div>
                            <div className='timeButtonsForExtendSession' style={{ height: '170px', overflowY: 'auto' }}>
                                <p>Ne zaman offline olmak istersiniz?</p>

                                {generateButtons()}
                            </div>
                            <div className='contract' style={{padding:'24px'}}>
                                <Checkbox style={{ transform: 'scale(1.5)', marginRight: '10px' }}  onChange={handleAggrementCheckboxValueChanged}></Checkbox>
                                <p>Belirttiğim indirimi, iş birliği sözleşmesi doğrultusunda tüm kullanıcılara eksiksiz olarak sunacağımı kabul ederim.</p>
                            </div>
                            <div className='companyBecomeOnlineButton'>
                                <button onClick={handleLiveRightNow}><img src={arrow} alt="arrow" /> Oturumu Uzat</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
