import { Box, Button, IconButton, Input, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import '../styles/ForgotPassword.css'
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';
import { Repository } from '../../data/repository/repository';

export default function DeleteAccountScreen() {
    const logo = require('../images/yerimLogo.png')
    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('')
    const [codeSent, setCodeSent] = useState(false);
    const [error, setError] = useState('');
    const arrow = require('../images/icons/Text--new-line.png')
    const currentURL = window.location.href
    console.log(currentURL);
    
    const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, '');

        if (numericValue.startsWith('0')) {
            setError('Telefon numarası 0 ile başlayamaz.');
        } else if (numericValue.length > 10) {
            setError('Telefon numarası en fazla 10 haneli olabilir.');
        } else {
            setError('');
            setPhoneNumber(numericValue);
        }
    }

    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value.toUpperCase());
    }


    const handleSendCode = async () => {
        try {
            const response = await Repository.sendSmsCode(phoneNumber);
            if (response === true) {
                setCodeSent(true)
            } else {
                setCodeSent(false)
                toast.error('Kod gönderilemedi. Lütfen tekrar deneyin.');

            }
        } catch (error) {
            console.error('Code error:', error);
            toast.error('Kod gönderilemedi. Lütfen tekrar deneyin.');
        }
    }


    const handleVerifySmsCode = async () => {
        try {
            const response = await Repository.verifySmsCode(phoneNumber, code);
            if (response === true) {
                const deleteAccountResponse = await Repository.deleteAccount(phoneNumber, code);
                
                if (deleteAccountResponse === 200 || deleteAccountResponse === 204) {
                    setError('');
                    toast.success('Hesap Başarıyla Silindi!');
                } else if (deleteAccountResponse === 208) {
                    toast.error('Kullanıcı hesabı için silme isteği zaten yapılmış ve hesap, isteğin ardından 30 gün içinde kalıcı olarak silinecektir.');
                }else if (deleteAccountResponse.response.status === 404) {
                    toast.error('Böyle bir kullanıcı bulunamadı. Lütfen girmiş olduğunuz numarayı kontrol edin.');
                }
                 else if (deleteAccountResponse === 500) {
                    toast.error('Bir hata meydana geldi. Lütfen tekrar deneyin.');
                }
            } else {
                toast.error('Girmiş olduğunuz doğrulama kodu hatalı. Lütfen kontrol edin.');
            }
        } catch (error) {
            console.error('Code error:', error);
            toast.error('Bir hata meydana geldi. Lütfen tekrar deneyin.');
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (codeSent) {
            handleVerifySmsCode();
        } else {
            handleSendCode();
        }
    }

    return (
        <Box className="overlay">
            <Box className="container-box">
                <Box display="flex" justifyContent="center" alignItems="center" mb="20px">
                    <img className='logo' src={logo} alt="Yerim Logo" />
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box className="form-group">
                        <h1 className='forgot-password-title'>Yerim Hesabımı Sil</h1>
                        <a className='link-tag' href={currentURL}>{currentURL}</a>
                        <label className='explanation'>Yerim hesabınızı silmek, hesabınız üzerinden erişilen tüm hesap bilgilerine, işlem geçmişlerine ve oturum açma bilgilerine kalıcı olarak erişemez hale getirecek ve hesabınız silinecektir.
                            Talebiniz doğrultusunda hesabınızı silmek isterseniz Yerim’e kayıt olduğunuz cep telefonu numarasını yazarak doğrulamadan geçin.</label>
                        <label className='enter-your-number'>Lütfen silmek istediğiniz hesaba ait telefon numarasını başında sıfır olmadan girin.</label>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <TextField
                                required
                                placeholder='Cep Telefonu Numarası'
                                label="Cep Telefonu Numarası"
                                value={phoneNumber}
                                disabled={codeSent === true}
                                onChange={handlePhoneNumber}
                                variant="outlined"
                                fullWidth
                                style={{ marginBottom: '15px' }}
                                InputProps={{
                                    startAdornment: (
                                        <MdOutlineEmail style={{ color: '#1D1E2599', width:'50px' }} />
                                    ),
                                }}
                            />

                        </Box>
                        <div style={{ display: 'flex' }}>
                            <p style={{ fontSize: '14px', color: 'red' }}>{error ? error : ''}</p>
                        </div>
                        {codeSent && (
                            <Box display="flex" flexDirection="column" alignItems="center" mt="20px">
                                <TextField
                                    required
                                    label="Gelen Kodu Girin"
                                    variant="outlined"
                                    value={code}
                                    onChange={handleCodeChange}
                                    fullWidth
                                    style={{ marginBottom: '15px' }}
                                />

                            </Box>

                        )}
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Button className='submit-button'  variant="contained" type="submit">
                                <img className='arrow' src={arrow} />{codeSent === true ? 'Hesabımı Sİl' : 'Kodu Gönder'}
                            </Button>
                        </Box>
                    </Box>
                </form>

            </Box>
        </Box>
    )
}
