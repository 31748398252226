import { Address } from "../common/Address"
import { Location } from "../common/Location"
import { Validator } from 'fluentvalidation-ts';
import { CompanyDTO } from "@/model/dto/CompanyDTO";
import { CreateCampaignRequest } from "@/model/request/CreateCampaignRequest";
import { ReflectionHelper } from "../../helpers/ReflectionHelper";

export interface CreateCompanyRequest {
  referenceCode?: string
  name: string
  type: string
  segments: string[] | null
  delegate: CompanyDelegate
  phoneNumber: string
  ownerPhoneNumber: string
  workingHours: string
  capacity: number | null
  webSiteLink: string
  photoUrl: string
  logoUrl: string
  address: Address
  email: string
  password: string
  contractUrl1: string
  contractPhotoUrl: string
  contractUrl2: string
  location: Location
  labels?: string[]
  taxCertificate: TaxCertificate
}

export class CreateCompanyRequestValidator extends Validator<CreateCompanyRequest> {
  constructor() {
    super();

    this.ruleFor('name')
      .notNull()
      .withMessage('Lütfen firma ismi giriniz')
      .notEmpty()
      .withMessage('Lütfen firma ismi giriniz');

    this.ruleFor('type')
      .notNull()
      .withMessage('Lütfen firma türü seçiniz')
      .notEmpty()
      .withMessage('Lütfen firma türü seçiniz');

    this.ruleFor('segments')
      .notNull()
      .withMessage('Lütfen firma segmenti seçiniz')
      .must((segments) => (segments?.length ?? 0) <= 3)
      .withMessage('Maksimum 3 firma segmenti seçebilirsiniz');

    /*this.ruleFor('phoneNumber')
      .notNull()
      .withMessage('Lütfen telefon numarası giriniz')
      .notEmpty()
      .withMessage('Lütfen telefon numarası giriniz');*/

    this.ruleFor('ownerPhoneNumber')
      .notNull()
      .withMessage('Lütfen telefon numarası giriniz')
      .notEmpty()
      .withMessage('Lütfen telefon numarası giriniz');

    const validateWorkingHours = (workingHours: string) => {
      const [openingTime, closingTime] = workingHours.split(' - ').map(time => time.trim());
      const [openingHour, openingMinute] = openingTime.split(':').map(Number);
      const [closingHour, closingMinute] = closingTime.split(':').map(Number);

      if (closingHour < openingHour || (closingHour === openingHour && closingMinute <= openingMinute)) {
        if (closingHour < 6) {
          return true;
        }
        return false;
      }
      return true;
    };


    this.ruleFor('workingHours')
      .notNull()
      .withMessage('Lütfen çalışma saati giriniz')
      .notEmpty()
      .withMessage('Lütfen çalışma saati giriniz')
      .matches(/^\d{2}:\d{2} - \d{2}:\d{2}$/)
      .withMessage('Lütfen çalışma saati giriniz')
      .must(validateWorkingHours)
      .withMessage('Kapanış saati açılış saatinden büyük olmalıdır');


    this.ruleFor('capacity')
      .notNull()
      .withMessage('Lütfen işletme kapasitesi giriniz')
      .greaterThan(0)
      .withMessage('İşletme kapasitesi sıfırdan büyük olmalıdır');

    /*this.ruleFor('webSiteLink')
      .notNull()
      .withMessage('Lütfen web sitesi giriniz')
      .notEmpty()
      .withMessage('Lütfen web sitesi giriniz');*/

    /*this.ruleFor('photoUrl')
      .notNull()
      .withMessage('Lütfen işletme görseli yükleyiniz')
      .notEmpty()
      .withMessage('Lütfen işletme görseli yükleyiniz');

    this.ruleFor('logoUrl')
      .notNull()
      .withMessage('Lütfen işletme logosu yükleyiniz')
      .notEmpty()
      .withMessage('Lütfen işletme logosu yükleyiniz');*/

    this.ruleFor('email')
    // .notNull()
    // .withMessage('Lütfen geçerli bir mail adresi giriniz')
    // .notEmpty()
    // .withMessage('Lütfen geçerli bir mail adresi giriniz')
    // .emailAddress()
    // .withMessage('Lütfen geçerli bir mail adresi giriniz');

    this.ruleFor('password')
    // .notNull()
    // .withMessage('Lütfen parola giriniz')
    // .notEmpty()
    // .withMessage('Lütfen parola giriniz');
  }
}

export interface CompanyDelegate {
  delegateName: string
  delegateSurname: string
  delegatePosition: string
}

export class CompanyDelegateValidator extends Validator<CompanyDelegate> {
  constructor() {
    super();

    this.ruleFor('delegateName')
      .notNull()
      .withMessage('Lütfen firma temsilcisi adını giriniz')
      .notEmpty()
      .withMessage('Lütfen firma temsilcisi adını giriniz');

    this.ruleFor('delegateSurname')
      .notNull()
      .withMessage('Lütfen firma temsilcisi soyadını giriniz')
      .notEmpty()
      .withMessage('Lütfen firma temsilcisi soyadını giriniz');

    /*this.ruleFor('delegatePosition')
      .notNull()
      .withMessage('Lütfen firma temsilcisi pozisyonunu giriniz')
      .notEmpty()
      .withMessage('Lütfen firma temsilcisi pozisyonunu giriniz');*/
  }
}

export interface TaxCertificate {
  tradeName: string
  taxOffice: string
  taxNo: string
  taxPayerName: string
  identificationNumber: string
}

export class TaxCertificateValidator extends Validator<TaxCertificate> {
  constructor() {
    super();

    /*this.ruleFor('tradeName')
      .notNull()
      .withMessage('Lütfen ticaret ünvanı giriniz')
      .notEmpty()
      .withMessage('Lütfen ticaret ünvanı giriniz');

    this.ruleFor('taxOffice')
      .notNull()
      .withMessage('Lütfen vergi dairesi giriniz')
      .notEmpty()
      .withMessage('Lütfen vergi dairesi giriniz');

    this.ruleFor('taxNo')
      .notNull()
      .withMessage('Lütfen vergi kimlik numaranızı giriniz')
      .notEmpty()
      .withMessage('Lütfen vergi kimlik numaranızı giriniz');

    this.ruleFor('taxPayerName')
      .notNull()
      .withMessage('Lütfen mükellef adı ve soyadını giriniz')
      .notEmpty()
      .withMessage('Lütfen mükellef adı ve soyadını giriniz');

      this.ruleFor('identificationNumber')
        .notNull()
        .withMessage('Lütfen tc kimlik numarasını giriniz')
        .notEmpty()
        .withMessage('Lütfen tc kimlik numarasını giriniz');*/
  }
}




export class CreateCompanyRequestFactory {

  public static setCity(request: CreateCompanyRequest | null, city: string): CreateCompanyRequest | null {
    if (!request) {
      return null
    }
    const clone = ReflectionHelper.deepCopy(request)
    if (clone.address.city != city) { // change this field and clear others only when a difference option is selected
      clone.address.city = city
      clone.address.district = null
      clone.address.neighborhood = null
      clone.address.street = ''
      clone.address.buildingNo = ''
    }
    return clone;
  }

  public static setDistrict(request: CreateCompanyRequest, district: string): CreateCompanyRequest {
    const clone = ReflectionHelper.deepCopy(request)
    if (clone.address.district != district) {
      clone.address.district = district
      clone.address.neighborhood = null
      clone.address.street = ''
      clone.address.buildingNo = ''
    }
    return clone;
  }

  public static setNeighborhood(request: CreateCompanyRequest, neighborhood: string): CreateCompanyRequest {
    const clone = ReflectionHelper.deepCopy(request)
    if (clone.address.neighborhood != neighborhood) {
      clone.address.neighborhood = neighborhood;
      clone.address.street = ''
      clone.address.buildingNo = ''
    }

    return clone;
  }

  public static setStreet(request: CreateCompanyRequest, street: string): CreateCompanyRequest {
    const clone = ReflectionHelper.deepCopy(request)
    if (clone?.address.street != street) {
      clone.address.street = street;
      clone.address.buildingNo = ''
    }

    return clone;
  }

  public static build(company: CompanyDTO | null): CreateCompanyRequest {

    const request = {
      name: company?.name ?? '',
      type: company?.type ?? '',
      segments: company?.segments ?? '',
      delegate: {
        delegateName: company?.delegate?.delegateName ?? '',
        delegateSurname: company?.delegate?.delegateSurname ?? '',
        delegatePosition: company?.delegate?.delegatePosition ?? '',
      },
      phoneNumber: company?.phoneNumber ?? '',
      ownerPhoneNumber: company?.ownerPhoneNumber ?? '',
      workingHours: company?.workingHours ?? '',
      capacity: company?.capacity ?? 0,
      webSiteLink: company?.webSiteLink ?? '',
      photoUrl: company?.photoUrl ?? '',
      logoUrl: company?.logoUrl ?? '',
      address: {
        city: company?.address?.city ?? 'İSTANBUL',
        district: company?.address?.district ?? '',
        neighborhood: company?.address?.neighborhood ?? '',
        street: company?.address?.street ?? '',
        buildingNo: company?.address?.buildingNo ?? '',
      },
      email: company?.email ?? '',
      password: company?.password,
      contractUrl1: company?.contractPhotoUrl1 ?? '',
      contractPhotoUrl: company?.contractPhotoUrl ?? '',
      contractUrl2: company?.contractPhotoUrl2 ?? '',
      location: company?.location ?? { longitude: 0, latitude: 0 },
      labels: company?.labels ?? [],
      taxCertificate: {
        tradeName: company?.taxCertificate?.tradeName ?? '',
        taxOffice: company?.taxCertificate?.taxOffice ?? '',
        taxNo: company?.taxCertificate?.taxNo ?? '',
        taxPayerName: company?.taxCertificate?.taxPayerName ?? '',
        identificationNumber: company?.taxCertificate?.identificationNumber ?? ''
      }
    } as CreateCompanyRequest;

    return request
  }


  public static extractWorkingHour(hours: string | null, hourType: WorkingHoursType): string | null {
    if (!hours) {
      return null
    }
    try {
      const stringWithoutSpaces = hours.replace(/\s+/g, '');
      return stringWithoutSpaces.split('-')[hourType == WorkingHoursType.start ? 0 : 1];
    }
    catch (error) {
      return null
    }
  }

}

export enum WorkingHoursType {
  start = 0,
  end = 1
}