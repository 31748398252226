import React from 'react'
import '../styles/style.css'

export default function PrivacyPolicyScreen() {
    const bg = require('../images/icons/Sol_color.png');

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-2">
                    <div className='backgroundDiv'>
                        <img src={bg} alt="background" />
                    </div>
                </div>
                <div className="col-sm-10">
                    <div className='contentDiv'>
                        <div className='titleOnline'>
                            <h2>
                                Gizlilik Politikası (Privacy Policy) ve Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni
                            </h2>
                            <hr style={{ width: '550px' }} />
                        </div>
                        <div className='row upperContent'>
                            <h6 style={{ color: '#1D1E2566' }}><label style={{ color: '#F15F50' }}>Güncellenme:</label> 12.10.2024</h6>
                            <p>


                                1. Kapsam İşbu Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni; (kısaca “Aydınlatma Metni”) ”Yerim” web sitesi, bağlantılı mobil web sitesi ve bağlantılı mobil uygulama (hepsi birlikte kısaca “Uygulama”) üzerinde üyelik hesabı oluşturan ve/veya Uygulama’ya üye olmadan çevrimiçi ziyaretçi olarak giriş yapan gerçek kişi üyelerin (hepsi birlikte kısaca “Üye(ler)”), kişisel verilerinin işlenmesiyle ilgili hususlara ilişkin olarak 6698 sayılı Kişisel Verilerin Korunması Kanunu (kısaca “KVKK”) ve ilgili mevzuat kapsamında aydınlatılması amacıyla hazırlanmıştır.
Üye, Uygulama’ya çevrimiçi ziyaretçi olarak giriş yaptığında ve/veya Uygulama’ya üye olurken işbu Aydınlatma Metni’ni okuduğunu, anladığını, kişisel veriler ile ilgili hususlara ilişkin olarak KVKK ve ilgili mevzuat hükümleri doğrultusunda aydınlatıldığını ve bilgilendirildiğini kabul, beyan ve taahhüt eder.
                                                                2. Veri Sorumlusu Duatepe Mah. Kurtuluş Cad. No: 36 Şişli / İstanbul adresinde faaliyet gösteren YERİM BİLİŞİM SİSTEMLERİ MEDYA SANAYİ VE TİCARET ANONİM ŞİRKETİ (kısaca “Şirket”) Üye’nin kişisel verileri ile ilgili “veri sorumlusu” sıfatını taşımaktadır.
                                3. Şirket Tarafından Toplanabilen Kişisel Veriler  - Kimlik Bilgileri: Adı, soyadı - İletişim Bilgileri: Cep telefonu numarası, e-posta adresi - Üye İşlem Bilgileri: Üye’nin Uygulama üzerindeki hareketleri; arama, gezinti, tıklama ve sayfa görüntüleme geçmişi, Uygulama destek ekibiyle yaptığı yazılı görüşmeler, Uygulama’ya ait destek veya iletişim formlarına ve/veya çeşitli form ve alanlara yazdığı mesajlar, kampanya kullanım bilgileri - İşlem Güvenliği Bilgileri: IP adresi/port bilgileri; giriş çıkış bilgileri; üyelik hesabına ilişkin bilgiler, üyelik şifre bilgileri; trafik verileri (bağlantı zamanı / süresi vb.); giriş yapılan cihaz, işletim sistemi, tarayıcı ve mobil uygulama sürümü bilgileri - Görsel ve İşitsel Bilgiler: Metin, fotoğraf, video, çağrı merkezi ses kayıtları - Konum Bilgileri: Ülkesi, Konum bilgileri - Ek Bilgiler: Cinsiyet
4. Kişisel Verilerin Toplanma Yöntemleri Yukarıda belirtilen kişisel veriler (kısaca “Kişisel Veriler”); söz konusu verilerin doğrudan Üye tarafından Uygulama üzerinden ve/veya Şirket’e ait çağrı merkezi telefon numaraları üzerinden ve/veya Şirket’e ait e-posta adresleri, Uygulama üzerindeki müşteri hizmetleri panelleri ve sair iletişim kanalları üzerinden Kişisel Veriler’in Şirket ile paylaşılması vasıtasıyla toplanabilmektedir. Kişisel Veriler, Üye Uygulama üzerinde çevrimiçi ziyaretçi olarak giriş yaptığında ve/veya üyelik hesabı oluşturduğunda ve/veya Uygulama üzerinde sunulan hizmetlerden yararlandığında, Şirket tarafından otomatik yöntemlerle de toplanabilmektedir.
                                5. Kişisel Verilerin İşlenmesinin Amaçları ve Huki Sebepleri  Şirket, yukarıda belirtilen Kişisel Veriler’i, farklı amaçlar için farklı hukuki sebeplere dayanarak işleyebilmektedir.
Kişisel Veriler’in hangi amaçlarla, hangi hukuki sebebe dayanarak işlendiğine dair bilgiler aşağıda paylaşılmaktadır:  5.1. Kişisel Veriler; Üye ile Şirket arasında kurulan Üyelik Sözleşmesi ve/veya Kullanım Koşulları çerçevesinde, KVKK m.5/2-c düzenlemesi uyarınca “bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması” hukuki sebebine dayalı olarak aşağıda amaçlarla işlenebilecektir:
5.1.1.Üyelik kaydının oluşturulabilmesi, üyelik hesabının doğrulanabilmesi ve üyelik hesabının Kişisel Veriler ile eşleştirilebilmesi.
5.1.2.Üye’nin Uygulama ile ilgili taleplerinin yanıtlanması, şikayetlerinin ve önerilerinin değerlendirilmesi, yaşadığı sorunların çözülmesi, destek sağlanabilmesi vb. müşteri memnuniyetinin sağlanabilmesi, müşteri hizmetlerinin sunulabilmesi ve bu hususlarda Üye’ye bildirimlerde bulunulması.
5.1.3. Üye’nin üyelik hesabını ve Uygulama’yı görüntüleyebilmesinin ve kullanabilmesinin sağlanması, Üye’nin Uygulama’dan ve Uygulama üzerindeki sair hizmetlerden yararlanabilmesinin sağlanması ve bu hususlarda Üye’ye bildirimlerde bulunulması
5.1.4. Üye’ye Üyelik hesabı ve Uygulama ilgili durumun, güncellemelerin, değişikliklerin ve gelişmelerin bildirilmesi
5.1.5. Üye’ye ilişkin Kişisel Veriler’in Uygulama verilerinin tutulduğu sunucularda tutulabilmesi
5.1.6.Uygulama’nın çalışması ve Üye tarafından kullanılabilmesi için gerekli temel fonksiyonların gerçekleştirilmesi
                                                                5.1.7.Üye’nin Uygulama üzerindeki üyelik hesabına giriş yapabilmesinin ve giriş yaptığı oturumunun sürekli açık kalmasının sağlanması, böylelikle Üye’nin her girişinde tekrar şifre bilgisi girmesine gerek kalmaksızın Uygulama’yı kullanabilmesinin sağlanması
5.1.8.Üye’nin Uygulama’ya gerçekleştireceği girişlerde hatırlanmasının ve tanınmasının sağlanması, Üye’nin Uygulama üzerindeki geçmiş işlemlerinin ve tercihlerinin hatırlanması
5.1.9.Üye’nin üyelik hesabı ile ilgili olarak gerçekleştirmek istediği işlemleri gerçekleştirebilmesi için Üye’ye destek/yardım sağlanması
                                5.2.Kişisel Veriler; KVKK m.5/2-ç düzenlemesi uyarınca “veri rumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması” hukuki sebebine dayalı olarak aşağıda amaçlarla işlenebilecektir:
5.2.1.Uygulama ve Üye ile ilgili olarak kolluk kuvvetleri, yasal ve idari mercilerden gelen taleplerin yerine getirilebilmesi, gelen resmi yazılara cevap verilmesi, talep ile ilgili alakalı bilgilerin paylaşılabilmesi için Kişisel Veriler’in görüntülenmesi ve incelenmesi
                                                                5.2.2.Uygulama’ya ilişkin işlemlerin yürürlükteki mevzuata uygun bir şekilde gerçekleştirilebilmesi
5.2.3.Şirket’in yürürlükteki mevzuat uyarınca saklamakla yükümlü olduğu Kişisel Veriler’in, ilgili mevzuat uyarınca belirtilen süreler boyunca saklanabilmesi
5.2.4.Şirket’in ve Şirket ile ilişki içerisinde olan kişi ve kurumların teknik, hukuki ve ticari güvenliğinin teminini sağlamak
                                                                5.3.Kişisel Veriler; KVKK m.5/2-e düzenlemesi uyarınca “bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması” hukuki sebebine dayalı olarak aşağıda amaçlarla işlenebilecektir:
                                                                5.3.1.Şirket’in Üye ile arasındaki olası uyuşmazlıklarda yasal haklarını kullanabilmesi için, ilgili zamanaşımı süreleri boyunca Kişisel Veriler’in saklanması
                                                                5.3.2.Şirket ile Üye arasındaki bir uyuşmazlık halinde, Şirket’in gerekli değerlendirmeyi yapabilmesi ve yasal süreçleri başlatabilmesi için Kişisel Veriler’i incelemesi ve kullanabilmesi
                                                                5.4.Kişisel Veriler; KVKK m.5/2-f düzenlemesi uyarınca “ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebebine dayalı olarak aşağıda amaçlarla işlenebilecektir:
                                                                5.4.1.Uygulama’nın ve Uygulama’da sunulan hizmetlerin Üye’nin kullanımı için kolaylaştırılması, özelleştirilmesi veya geliştirilmesi  5.4.2.Hile veya dolandırıcılık teşkil eden, güvenlik ihlali oluşturan veya şüpheli ya da hukuka aykırı olabilecek faaliyetlerin önlenebilmesi, tespit edilebilmesi, en aza indirilebilmesi ve soruşturulabilmesi, bu amaçlarla Kişisel Veriler’in görüntülenmesi, incelenmesi ve kullanılabilmesi 5.4.3.Uygulama ve/veya Üye ile ilgili herhangi bir şikayet ve/veya uyuşmazlığın çözümlenebilmesi, bu amaçla Kişisel Veriler’in görüntülenmesi, incelenmesi ve kullanılabilmesi 5.4.4.Üye’nin Uygulama üzerindeki hareketlerinin incelenebilmesi ve takip edilebilmesi  5.4.5.Uygulama’nın güvenliğinin izlenmesi ve geliştirilmesi
                                                                6. Kişisel Verilerin Paylaşılması 6.1. Şirket; müşteri hizmetleri, sunucu barındırma (hosting), hukuk, yazılım desteği, e-posta/sms gönderim hizmeti, analiz hizmetleri, bakım/teknik destek hizmetleri ve bunun gibi çeşitli konularda destek hizmetleri aldığı firmalarla ve iş ortaklarıyla, işbu Aydınlatma Metni’nin 5.1. , 5.2. , 5.3. ve 5.4. numaralı maddelerinde sayılan amaçlardan birisinin varlığı halinde ve söz konusu maddelerde veri işleme amaçları için belirtilen hukuki sebeplere dayalı olarak Kişisel Veriler’i paylaşabilir.
                                6.2. Şirket; Üye’ye ilişkin Kişisel Veriler’i, işbu Aydınlatma Metni’nin 5.1. , 5.2. , 5.3. ve 5.4. numaralı maddelerinde sayılan amaçlardan birisinin varlığı halinde ve söz konusu maddelerde veri işleme amaçları için belirtilen hukuki sebeplere dayalı olarak diğer Uygulama üyeleri ile paylaşabilir.
                                                                6.3. Kolluk kuvvetleri, idari merciler ve/veya yasal merciler tarafından gönderilen resmi yazılar kapsamında Uygulama ve/veya Üye ile ilgili olarak herhangi bir bilgi-belge talep edilmesi halinde, Kişisel Veriler, söz konusu resmi yazılara cevap verilebilmesi ve talebin karşılanabilmesi amacıyla, ilgili kolluk kuvvetleri, idari merciler ve/veya yasal merciler ile, KVKK m.5/2-ç düzenlemesi uyarınca “veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması” hukuki sebebine dayalı olarak paylaşılabilecektir.
                                                                Buna ek olarak Şirket; kolluk kuvvetleri, idari merciler ve/veya yasal merciler ile, işbu Aydınlatma Metni’nin 5.1. , 5.2. , 5.3. ve 5.4. numaralı maddelerinde sayılan amaçlardan birisinin varlığı halinde ve söz konusu maddelerde veri işleme amaçları için belirtilen hukuki sebeplere dayalı olarak da Kişisel Veriler’i paylaşabilir.
                                6.4. Şirket, yürürlükteki herhangi bir mevzuat hükmü uyarınca düzenlenen yasal yükümlülüklerini yerine getirebilmek için destek hizmetleri alabilmek amacıyla, KVKK m.5/2-ç düzenlemesi uyarınca “veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması” hukuki sebebine dayalı olarak, üçüncü kişi şirketlerle Kişisel Veriler’i paylaşabilir.
                                                                7. Üye’nin Hakları Üye, info@yerim.com adresine mail atarak kendisiyle ilgili:
                                a) Kişisel Veriler’in işlenip işlenmediğini öğrenme, b) İşlenmişse bilgi talep etme, c) İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme, ç) Yurtiçinde/yurtdışında aktarıldığı 3. kişileri bilme, d) Eksik / yanlış işlenmişse düzeltilmesini isteme, e) KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini / yok edilmesini isteme, f) Kişisel Veriler’in aktarıldığı üçüncü kişilere yukarıda sayılan (d) ve (e) bentleri uyarınca yapılan işlemlerin bildirilmesini isteme,
                                                                g) münhasıran otomatik sistemler ile analiz edilmesi nedeniyle Üye aleyhine bir sonucun ortaya çıkmasına itiraz etme, ğ) Kişisel Veriler’in KVKK’ya aykırı olarak işlenmesi sebebiyle Üye’nin zarara uğraması halinde zararın giderilmesini talep etme
                                                                hakkına sahiptir.
                                7.1. KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde Üye hesabının silinmesini / yok edilmesini isteme durumunda https://account.yerimapp.com/delete-account adresinden bu işlemi yapabilmektedir.
                                8. Kişisel Verilerin Güvenliğinin Sağlanması İçin Alınan Tedbirler Şirket tarafından Kişisel Veriler’in güvenliğini sağlamak amacıyla alınan teknik ve idari tedbirler şunlardır: - İki faktörlü kimlik doğrulama (2FA) - Düzenli güvenlik taramaları ve denetimler - Yetkisiz erişimi engellemek için rol tabanlı erişim kontrolleri - Şifreleme, erişim kontrolleri, güvenlik duvarları, bilgi güvenliği testleri, yedekleme ve https şifreleme protokolü gibi yöntemler - Personel eğitimi ve farkındalık artırma çalışmaları - Güvenlik açıklarını tespit ve giderme süreçleri yer almaktadır.
                                9. Çeşitli Hükümler İşbu Aydınlatma Metni, Kişisel Veriler’in sadece Şirket tarafından işlenmesi açısından geçerlidir.
                                Üye, Şirket haricindeki üçüncü kişilerle kişisel verilerini paylaştığı ve/veya Uygulama üzerinden üçüncü kişi internet sitelerine yönlendirildiği takdirde, söz konusu üçüncü kişilerin kişisel veri politikası ve kişisel veriler ile ilgili uygulamalarına tabi olacaktır.
                                Şirket, belirtilen durumlardaki üçüncü kişilerin kişisel veriler ile ilgili eylemleri sebebiyle herhangi bir şekilde sorumlu tutulamacak olup, Üye belirtilen durumlardan ötürü doğabilecek her türlü talep, şikayet ve zararlarını söz konusu üçüncü kişilere yönlendireceğini kabul, beyan ve taahhüt eder.
                                10. Aydınlatma Metni’nde Yapılacak Değişiklikler Şirket, işbu Aydınlatma Metni’nde her zaman değişiklik yapabilir. Bu değişiklikler, değiştirilmiş yeni Aydınlatma Metni’nin yayınlanmasıyla birlikte derhal geçerlilik kazanır. İşbu Aydınlatma Metni’nde değişiklikler ile ilgili olarak Üye’ye gerekli bilgilendirme yapılacaktır.


                                     </p>
                                                                  </div>
                              </div>
                                                          </div><br/>
                                                      </div>
                                                  </div>
                                    )
                                }
