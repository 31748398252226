import { Validator } from 'fluentvalidation-ts';

export interface Location {
    longitude: number
    latitude: number
  }

export class LocationValidator extends Validator<Location> {
  constructor() {
    super();

    /*this.ruleFor('latitude')
      .notNull()
      .withMessage('Lütfen enlem bilgisini giriniz')
      .greaterThanOrEqualTo(-90)
      .withMessage('Enlem minimum -90 olabilir')
      .lessThanOrEqualTo(90)
      .withMessage('Enlem maksimum 90 olabilir');

    this.ruleFor('longitude')
      .notNull()
      .withMessage('Lütfen boylam bilgisini giriniz')
      .greaterThanOrEqualTo(-180)
      .withMessage('Enlem minimum -180 olabilir')
      .lessThanOrEqualTo(180)
      .withMessage('Enlem maksimum 180 olabilir');*/
  }
}